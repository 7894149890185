import { client } from "./index";

const URLS = {
  documentList: "/document",
  documentReject: "/document/reject/",
  sendDocument: "/document/send",
  invoiceList: "/document/invoice",
  sendInvoice: "/document/invoice/send",
  invoiceDetail: "/document/invoice/",
  companies: "/document/company"
};

export const getDocumentList = async (params) => {
  return await client.get(URLS.documentList, { params });
};

export const getInvoiceList = async (params) => {
  return await client.get(URLS.invoiceList, { params });
};

export const getInvoiceDetail = async (invoiceNumber) => {
  return await client.get(URLS.invoiceDetail + invoiceNumber);
};

export const getDocumentDetail = async (id) => {
  const url = `/document/${id}`;
  return await client.get(url);
};

export const createDocuments = async (data) => {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return await client.post(URLS.documentList, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const createAdminSign = async (documentId, sign) => {
  const formData = new FormData();
  formData.append("documentId", documentId);
  formData.append("checkerSign", sign);

  return await client.patch(URLS.documentList, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const createInvoice = async (params) => {
  return await client.post(URLS.invoiceList, params);
};

export const sendDocument = async (formData) => {
  return await client.post(URLS.sendDocument, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const sendInvoice = async (formData) => {
  return await client.post(URLS.sendInvoice, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const deleteInvoice = async (invoiceNumber) => {
  return await client.delete(URLS.invoiceDetail + invoiceNumber);
};

export const getCompanies = async () => {
  return await client.get(URLS.companies);
};

export const rejectDocument = async (documentId) => {
  return await client.post(URLS.documentReject + documentId);
};
