import GlobalHeader from "../../../layouts/GlobalHeader";
import React, { useState } from "react";
import { backConfirm, setAlert } from "../../../store/alert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../router/routes";
import { image } from "../../../themes/theme";
import styled from "styled-components";
import Input from "../../../components/input/Input";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 65vh;
  margin-top: 30%;
`;

const ContentWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 96.5px;
  gap: 24px;
`;

const Logo = styled.div`
  margin-top: 180px;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

const Header = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Content = styled.div`
  font-size: 15px;
  color: #a7a7a7;
`;

const AgreeBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  & p {
    color: #202020;
  }

  & span {
    color: #e96c12;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background: ${(props) => (props.disabled ? "#d7d7d7" : "#E96C12")};
  border-radius: 999px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
`;

const initData = {
  username: "",
  agree: false
};

const CustomerWithdrawPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(initData);

  const exit = () => {
    dispatch(backConfirm(() => navigate(ROUTES.splash)));
  };

  const onChange = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled = () => {
    let isValid = false;
    if (data.agree && data.username) {
      isValid = true;
    }
    return !isValid;
  };

  const expireMember = async () => {
    if (data.agree && data.username) {
      dispatch(
        setAlert({
          message: "회원탈퇴 신청 처리가 되었습니다.",
          onClick: () => {
            localStorage.removeItem("sunjin-user-session");
            navigate(ROUTES.splash);
          }
        })
      );
    }
  };

  return (
    <>
      <GlobalHeader title={"회원탈퇴"} left={"닫기"} onLeftClick={exit}></GlobalHeader>

      <Wrap>
        <ContentWrap>
          <Logo>
            <img src={image.logo} alt="logo" width={170} height={50} />
          </Logo>
          <Text>그 동안 이용해 주셔서 감사합니다.</Text>

          <div style={{ width: "90%", display: "flex", flexDirection: "column", gap: "16px" }}>
            <Input
              width="100%"
              type="text"
              mode="numeric"
              name="billingTime"
              placeholder="회원 아이디를 입력해주세요."
              value={data.username}
              onChange={(value) => onChange("username", value)}
            />
            <AgreeBox onClick={() => onChange("agree", !data?.agree)}>
              <img src={data.agree ? image.checkbox : image.noneCheckbox} />
              <p>회원 탈퇴 신청을 동의합니다.</p>
            </AgreeBox>
          </div>
        </ContentWrap>
      </Wrap>
      <Container>
        <Button disabled={isDisabled()} onClick={expireMember}>
          회원 탈퇴 신청
        </Button>
      </Container>
    </>
  );
};

export default CustomerWithdrawPage;
