import styled from 'styled-components';

export const AppleIcon = styled.button`
  position: relative;
  img:hover {
    cursor: pointer;
  }
  #appleid-signin {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    visibility: hidden;
  }
`;