import { useNavigate, useParams } from "react-router-dom";
import GlobalHeader from "../../layouts/GlobalHeader";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { image } from "../../themes/theme";
import { setAlert, setConfirm, simpleAlert } from "../../store/alert";
import InvoicePage from "../Invoice";
import WorkConfirm from "../WorkConfirm";
import html2canvas from "html2canvas";
import { deleteInvoice } from "../../api/document";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { animated, useSpring } from "react-spring";
import { useDrag } from "react-use-gesture";

const Root = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 130px);
    overflow: scroll;
    position: relative;
    background: #999999;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  Content: styled.div`
    padding: 80px;
    //width: 48px;
    //height: 48px;
  `,
  ButtonWrap: styled.div`
    background: #ffffff;
    width: 100%;
    min-width: 360px;
    max-width: 767px;
    height: 72px;
    box-shadow: 0 2px 8px #0000001f;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 4px;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
  `,
  Button: styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    cursor: pointer;
    background: ${(props) => (props.$color ? "#F9F9F9" : "#E96C12")};
    color: ${(props) => (props.$color ? "#444444" : "#FFFFFF")};
  `
};

export default function PreviewPage() {
  /*
   * domain - document: "작업확인서", invoice: "거래명세서",
   * id - 대상 고유번호 작업확인서 id, 거래명세서 id
   */
  const { domain, id } = useParams();
  const page = domain === "document" ? "작업확인서" : "거래명세서";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* 삭제 */
  const onRemove = async () => {
    try {
      await deleteInvoice(id);

      dispatch(
        setAlert({
          message: `${page}가 삭제되었습니다.`,
          onClick: backTo
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  /* 이미지 다운로드 */
  const onImageDownload = () => {
    const input = document.getElementsByClassName("preview")[0];

    // A4 size in pixels at 300 DPI
    const a4Width = 2481;
    const a4Height = 3507;

    // Create a container for the input element to fix its size
    const container = document.createElement("div");
    container.style.width = `${a4Width}px`;
    container.style.height = `${a4Height}px`;
    container.style.position = "fixed";
    container.style.top = "-10000px"; // Hide it off-screen
    container.style.left = "0";
    container.style.overflow = "hidden"; // Hide overflow content

    // Clone the input element and scale it to fit A4
    const clonedInput = input.cloneNode(true);
    clonedInput.style.transform = `scale(${Math.min(
      a4Width / input.offsetWidth,
      a4Height / input.offsetHeight
    )})`;
    clonedInput.style.transformOrigin = "top left";
    container.appendChild(clonedInput);

    // Append the container to the body
    document.body.appendChild(container);

    html2canvas(container, {
      useCORS: true,
      width: a4Width,
      height: a4Height,
      scale: 1
    })
      .then((canvas) => {
        // Remove the container from the document
        document.body.removeChild(container);

        // Create a resized canvas with A4 dimensions
        const resizedCanvas = document.createElement("canvas");
        resizedCanvas.width = a4Width;
        resizedCanvas.height = a4Height;
        const ctx = resizedCanvas.getContext("2d");

        // Fill the background with white
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, a4Width, a4Height);

        // Draw the content on the resized canvas
        ctx.drawImage(canvas, 0, 0, a4Width, a4Height);

        return new Promise((resolve, reject) => {
          resizedCanvas.toBlob((blob) => {
            // 안드로이드 전용
            const reader = new FileReader();
            reader.onload = function () {
              if (window.imageProcessor) {
                window.imageProcessor.processImageData(reader.result.split(",")[1]);
              }
            };
            reader.readAsDataURL(blob);

            // iOS 전용
            const iosReader = new FileReader();
            iosReader.onload = function () {
              if (window.webkit?.messageHandlers) {
                window.webkit.messageHandlers.imageDownloader.postMessage(iosReader.result);
              }
            };
            iosReader.readAsDataURL(blob);

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "download.jpg"); // Use a default file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }, "image/jpeg");
        });
      })
      .catch((e) => {
        console.log(e);
        dispatch(simpleAlert(`이미지 다운로드에 실패하였습니다.`));
      });
  };

  /* 뒤로가기 */
  const backTo = () => navigate(-1);

  const pos = useSpring({ x: 0, y: 0 });

  const bindPos = useDrag((params) => {
    pos.x.set(params.offset[0]);
    pos.y.set(params.offset[1]);
  });

  return (
    <>
      <GlobalHeader
        title={`${page} 미리보기`}
        left="닫기"
        onLeftClick={backTo}
        rightIcon={domain === "document" ? "" : image.deleteBasket}
        onRightClick={() =>
          dispatch(
            setConfirm({
              message: `정말로 ${page}를\n삭제하시겠습니까?`,
              onClick: onRemove
            })
          )
        }
      />
      <Root.Container>
        <animated.div
          {...bindPos()}
          style={{
            x: pos.x,
            y: pos.y
          }}
        >
          <TransformWrapper initialScale={1} minScale={-5} maxScale={5}>
            <TransformComponent>
              <Root.Content className="preview">
                {domain === "document" ? <WorkConfirm id={id} /> : <InvoicePage id={id} />}
              </Root.Content>
            </TransformComponent>
          </TransformWrapper>
        </animated.div>
        <Root.ButtonWrap>
          <Root.Button $color={true} onClick={backTo}>
            닫기
          </Root.Button>
          <Root.Button onClick={onImageDownload}>이미지 다운로드</Root.Button>
        </Root.ButtonWrap>
      </Root.Container>
    </>
  );
}
