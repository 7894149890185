import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { alert } from "../store/alert";
import { modal } from "../store/modal";
import Alert from "../components/Alert";
import Modal from "../components/modal";
import { getLoginSession } from "../utils/storage";
import ROUTES from "../router/routes";

const noSession = ["/", "/login", "/join", "/join/sns", "/login/auth/callback", "/find/*"];

function Layout() {
  const navigate = useNavigate();
  const location = useLocation();

  const { open: modalOpen } = useSelector(modal);
  const { open: alertOpen } = useSelector(alert);

  useEffect(() => {
    const tokenInfo = getLoginSession();
    const path = location.pathname;

    const isPolicyPath = path.startsWith("/policy/");
    const isCustomerWithdraw = path.startsWith("/customer/withdraw");
    const isDocumentsSignPath = path.startsWith("/documents/sign/");

    if (
      tokenInfo &&
      noSession.some((p) => path === p || (p.includes("*") && path.startsWith(p.replace("*", ""))))
    ) {
      navigate(ROUTES.main);
    } else if (
      !tokenInfo &&
      !isPolicyPath &&
      !isCustomerWithdraw &&
      !isDocumentsSignPath &&
      !noSession.some((p) => path === p || (p.includes("*") && path.startsWith(p.replace("*", ""))))
    ) {
      navigate(ROUTES.splash);
    }
  }, [location, navigate]);

  return (
    <>
      <Outlet />
      {alertOpen && <Alert />}
      {modalOpen && <Modal />}
    </>
  );
}

export default Layout;
