import { createBrowserRouter } from "react-router-dom";
import ROUTES from "./routes";
import Layout from "../layouts/Layout";
import SplashPage from "../pages/Splash";
import LoginPage from "../pages/Login";
import JoinPage from "../pages/Join";
import InvoicePage from "../pages/Invoice";
import FindId from "../pages/Find/Id/FindId";
import FindPassword from "../pages/Find/Password/FindPassword";
import SuccessId from "../pages/Find/Id/SuccessId";
import SuccessPassword from "../pages/Find/Password/SuccessPassword";
import MainPage from "../pages/Main";
import DocumentsPage from "../pages/Documents";
import SNSJoinPage from "../pages/Join/sns";
import My from "../pages/My";
import SendPage from "../pages/Send";
import PreviewPage from "../pages/Preview";
import CreateDocumentPage from "../pages/CreateDocument";
import Policy from "../pages/Policy";
import ChangePassword from "../pages/My/Change/ChangePassword";
import ChangePhone from "../pages/My/Change/ChangePhone";
import ChangeBiz from "../pages/My/Change/ChangeBiz";
import OAuth2Login from "../pages/Login/oAuth2/OAuth2Login";
import SuccessDocument from "../pages/CreateDocument/SuccessDocument";
import ExpireMember from "../pages/My/Change/ExpireMember";
import DocumentHistoryPage from "../pages/DocumentHistory";
import CustomerWithdrawPage from "../pages/Customer/Withdraw";

const Router = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      { path: ROUTES.splash, element: <SplashPage /> },
      { path: ROUTES.login, element: <LoginPage /> },
      { path: ROUTES.join, element: <JoinPage /> },
      { path: ROUTES.sns, element: <SNSJoinPage /> },
      { path: ROUTES.invoice, element: <InvoicePage /> },
      { path: ROUTES.main, element: <MainPage /> },
      { path: ROUTES.documents, element: <DocumentsPage /> },
      { path: ROUTES.documentHistory, element: <DocumentHistoryPage /> },
      { path: ROUTES.send, element: <SendPage /> },
      { path: ROUTES.preview, element: <PreviewPage /> },
      { path: ROUTES.createDocument, element: <CreateDocumentPage /> },
      { path: ROUTES.successDocument, element: <SuccessDocument /> },
      { path: ROUTES.signDocument, element: <CreateDocumentPage /> },
      { path: ROUTES.findId, element: <FindId /> },
      { path: ROUTES.successId, element: <SuccessId /> },
      { path: ROUTES.findPw, element: <FindPassword /> },
      { path: ROUTES.successPw, element: <SuccessPassword /> },
      { path: ROUTES.my, element: <My /> },
      { path: ROUTES.changePw, element: <ChangePassword /> },
      { path: ROUTES.changePhone, element: <ChangePhone /> },
      { path: ROUTES.changeBiz, element: <ChangeBiz /> },
      { path: ROUTES.invoice, element: <InvoicePage /> },
      { path: ROUTES.policy, element: <Policy /> },
      { path: ROUTES.oAuth2Callback, element: <OAuth2Login /> },
      { path: ROUTES.expireMember, element: <ExpireMember /> },
      { path: ROUTES.customerWithdraw, element: <CustomerWithdrawPage /> }
    ]
  }
]);

export default Router;
