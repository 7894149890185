import React from "react";
import ReactDOM from "react-dom/client";

// router
import { RouterProvider } from "react-router-dom";
import Router from "./router/Router";

// theme
import { ThemeProvider } from "styled-components";
import GlobalTheme from "./themes/theme";
import GlobalStyle from "./themes/base";

// redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={GlobalTheme}>
                <GlobalStyle />
                <RouterProvider router={Router} />
            </ThemeProvider>
        </PersistGate>
    </Provider>
);
