import styled, { css } from "styled-components";

export const WarnStyle = css`
  color: rgba(255, 11, 11, 1);
  border-color: rgba(255, 11, 11, 1);
`;

export const SelectStyle = css`
  width: ${(props) => props.width || "auto"};
  height: 48px;
  border: 1px solid
    ${(props) => (props.$hasValue ? "rgba(225, 225, 225, 1)" : "rgba(32, 32, 32, 1)")};
  border-radius: 8px;
  font-size: 16px;
  background: #ffffff;
  appearance: none;
  padding: 12px 50px 12px 12px;
  color: rgba(32, 32, 32, 1);

  &:focus {
    outline: none;
    border-color: rgba(32, 32, 32, 1);
  }

  &:disabled {
    background: rgba(249, 249, 249, 1);
    border-color: rgba(225, 225, 225, 1);
  }
`;

export const StyledSelect = styled.select`
  ${SelectStyle}
  ${(props) => props.$warn && WarnStyle}
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
`;
