import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import "moment/locale/ko";
import { addNote, changeNote, getNoteDetails, getNoteList } from "../../../../api/note";
import { CalendarModal, Dot, MemoForm } from "./style";
import { setAlert, simpleAlert } from "../../../../store/alert";
import { useDispatch } from "react-redux";

export default function MainCalendar() {
    const dispatch = useDispatch();
    const [value, setValue] = useState(new Date());
    const [openMemo, setOpenMemo] = useState(false);
    const [dates, setDates] = useState([]);
    const [noteDateList, setNoteDateList] = useState([]);
    const [memo, setMemo] = useState("");
    const [memoData, setMemoData] = useState();

    const noteListData = async () => {
        try {
            const response = await getNoteList();
            setNoteDateList(response.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const onDateChange = async (date) => {
        setValue(date);
        setOpenMemo(true);

        const startOfWeek = moment(date).subtract(3, "day");
        const endOfWeek = moment(date).add(3, "day");

        let dates = [];
        for (
            let d = moment(startOfWeek);
            d.isBefore(endOfWeek) || d.isSame(endOfWeek);
            d.add(1, "days")
        ) {
            dates.push(d.format("YYYY-MM-DD"));
        }

        setDates(dates);

        const findData = noteDateList.find(
            (data) => data.noteDate === moment(date).format("YYYY-MM-DD")
        );

        if (findData) {
            const response = await getNoteDetails(findData.id);
            setMemoData(response.data.data);
        }
    };

    const addDot = ({ date }) => {
        const contents = [];
        const formatDate = moment(date).format("YYYY-MM-DD");

        noteDateList
            .map((item) => item.noteDate)
            .map((noteDate, i) => {
                if (noteDate === formatDate) {
                    contents.push(
                        <React.Fragment key={i}>
                            <Dot.Circle />
                        </React.Fragment>
                    );
                }
            });

        return contents;
    };

    /* 메모 등록 */
    const saveMemo = async () => {
        try {
            await addNote(value, memo);
            dispatch(
                setAlert({
                    message: `등록되었습니다.`,
                    onClick: () => {
                        setOpenMemo(false);
                        noteListData();
                    }
                })
            );
        } catch (e) {
            console.error("메모 등록 실패", e);
            dispatch(simpleAlert("메모 등록 실패하였습니다."));
        }
    };

    /* 메모 수정 */
    const updateMemo = async () => {
        try {
            await changeNote(memoData);
            dispatch(
                setAlert({
                    message: `수정되었습니다.`,
                    onClick: () => {
                        setOpenMemo(false);
                        noteListData();
                    }
                })
            );
        } catch (e) {
            console.error("메모 수정 실패", e);
            dispatch(simpleAlert("메모 수정 실패하였습니다."));
        }
    };

    useEffect(() => {
        noteListData();
    }, []);

    useEffect(() => {
        setMemo("");
        setMemoData(null);
    }, [openMemo, value]);

    return (
        <div>
            {!openMemo && (
                <CalendarModal
                    locale="ko"
                    calendarType="US"
                    onChange={onDateChange}
                    value={value}
                    formatDay={(locale, date) => moment(date).format("D")}
                    navigationLabel={null}
                    showNeighboringMonth={false}
                    next2Label={null}
                    prev2Label={null}
                    tileContent={addDot}
                    minDetail="month"
                    maxDetail="month"
                />
            )}
            {openMemo && (
                <MemoForm.Container>
                    <MemoForm.Header>{moment(value).format("YYYY년 MM월")}</MemoForm.Header>
                    <MemoForm.DayForm>
                        {dates.map((date, index) => (
                            <MemoForm.Day key={index}>
                                <p
                                    onClick={() => setValue(date)}
                                    className={
                                        moment(value).format("YYYY-MM-DD") === date && "active"
                                    }
                                >
                                    {moment(date).format("DD")}
                                </p>
                                {noteDateList.map((item) => item.noteDate).includes(date) && (
                                    <MemoForm.Circle />
                                )}
                            </MemoForm.Day>
                        ))}
                    </MemoForm.DayForm>
                    <MemoForm.MemoContainer>
                        <h1>메모</h1>
                        {memoData ? (
                            <>
                                <textarea
                                    placeholder="내용을 입력해 주세요."
                                    value={memoData?.content}
                                    onChange={(e) =>
                                        setMemoData((prev) => ({
                                            ...prev,
                                            content: e.target.value
                                        }))
                                    }
                                    maxLength={255}
                                />
                                <span>{memoData?.content?.length} / 255</span>
                            </>
                        ) : (
                            <>
                                <textarea
                                    placeholder="내용을 입력해 주세요."
                                    value={memo}
                                    onChange={(e) => setMemo(e.target.value)}
                                    maxLength={255}
                                />
                                <span>{memo.length} / 255</span>
                            </>
                        )}
                    </MemoForm.MemoContainer>
                    <MemoForm.ButtonCont>
                        <MemoForm.Button onClick={() => setOpenMemo(false)}>취소</MemoForm.Button>
                        <MemoForm.Button active onClick={memoData ? updateMemo : saveMemo}>
                            {memoData ? "수정" : "등록"}
                        </MemoForm.Button>
                    </MemoForm.ButtonCont>
                </MemoForm.Container>
            )}
        </div>
    );
}
