import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GlobalHeader from "../../layouts/GlobalHeader";
import styled from "styled-components";
import { image } from "../../themes/theme";
import { emailValidation, phoneValidation } from "../../utils/validation";
import { setAlert, setConfirm, simpleAlert } from "../../store/alert";
import { useDispatch } from "react-redux";
import InvoicePage from "../Invoice";
import WorkConfirm from "../WorkConfirm";
import html2canvas from "html2canvas";
import { sendDocument, sendInvoice } from "../../api/document";
import { SyncLoader } from "react-spinners";

const Root = {
  Container: styled.div`
    width: 100%;
    min-width: 360px;
    max-width: 767px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 12px 0 40px 0;
    position: relative;
    height: calc(100vh - 72px);
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 0 16px;
  `,
  Tab: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background: ${(props) => (props.$color ? "#E96C12" : "#f9f9f9")};
    color: ${(props) => (props.$color ? "#FFFFFF" : "#444444")};
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    gap: 12px;
  `,
  Box: styled.div`
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${(props) => (props.$warm ? "#FF9090" : "#e1e1e1")};
    overflow: hidden;
  `,
  Wrap: styled.div`
    display: flex;
    justify-content: space-between;

    & button {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 12px;
    }
  `,
  Input: styled.input`
    width: 80%;
    height: 48px;
    padding: 8px 12px;
    font-size: 15px;
    color: ${(props) => (props.$warm ? "#FF0B0B" : "#202020")};
    &::placeholder {
      font-size: 15px;
      font-weight: 400;
      color: #909090;
    }
  `,
  AddButton: styled.div`
    width: 100%;
    background: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 48px;

    & p {
      font-size: 15px;
      color: #444444;
    }
  `,
  Text: styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #202020;
  `,
  Warm: styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #ff0b0b;
  `,
  ButtonWrap: styled.div`
    background: #ffffff;
    width: 100%;
    min-width: 360px;
    max-width: 767px;
    height: 72px;
    box-shadow: 0 2px 8px #0000001f;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    position: fixed;
    gap: 4px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
  `,
  Button: styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    cursor: pointer;
    border: ${(props) => props.$color && "1px solid #E96C12"};
    background: ${(props) => (props.$color ? "#FFFFFF" : props.disabled ? "#E96C12" : "#d7d7d7")};
    color: ${(props) => (props.$color ? "#E96C12" : "#FFFFFF")};
  `
};

const initInputValue = {
  value: "",
  warm: false
};

export default function SendPage() {
  /*
   * domain - document: "작업확인서", invoice: "거래명세서",
   * id - 대상 고유번호 작업확인서 id, 거래명세서 id
   */
  const { domain, id } = useParams();
  const page = domain === "document" ? "작업확인서" : "거래명세서";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tab, setTab] = useState(false);
  const [items, setItems] = useState([initInputValue]);
  const [loading, setLoading] = useState(false);

  /* 인풋 추가 이벤트 */
  const onAddInput = () => setItems((prev) => [...prev, initInputValue]);

  /* 인풋 삭제 이벤트 - 인풋 개수가 하나일 때 초기화시킴 */
  const onRemoveInput = (index) => {
    if (items.length === 1) return setItems([initInputValue]);
    setItems(items.filter((p, i) => i !== index));
  };

  /* 인풋 값 셋팅 */
  const onInputChange = (e, index) => {
    const newValue = items.map((item, i) =>
      i === index ? { ...item, value: e.target.value, warm: false } : item
    );
    setItems(newValue);
  };

  /* 전송하기 전 데이터 검사 */
  const onSubmit = () => {
    if (!isValidation()) return;

    dispatch(
      setConfirm({
        message: `${page}를 전송하시겠습니까?`,
        onClick: OnSave
      })
    );
  };

  /* 전송 */
  const OnSave = async () => {
    /* 작업확인서 전송 API */
    setLoading(true);
    try {
      const formData = await setFormData();
      domain === "document" ? await sendDocument(formData) : await sendInvoice(formData);

      dispatch(
        setAlert({
          message: `${page}가 정상적으로\n전송되었습니다.`,
          onClick: () => navigate(-1)
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(simpleAlert(`${page} 전송 실패하였습니다.`));
    } finally {
      setLoading(false);
    }
  };

  const setFormData = async () => {
    let phone = [];
    let email = [];
    let pages = [];

    const input = document.getElementsByClassName("preview")[0];
    input.style.display = "block";
    input.style.width = "794px";

    const MAX_HEIGHT = 1123;

    await html2canvas(input, { useCORS: true, scale: 1 }).then((canvas) => {
      let startY = 0;
      const totalHeight = canvas.height;

      while (startY < totalHeight) {
        const height = Math.min(totalHeight - startY, MAX_HEIGHT);
        const bufferCanvas = document.createElement("canvas");
        bufferCanvas.width = canvas.width;
        bufferCanvas.height = height;
        const context = bufferCanvas.getContext("2d");

        context.drawImage(canvas, 0, startY, canvas.width, height, 0, 0, canvas.width, height);

        pages.push(
          new Promise((resolve) => {
            bufferCanvas.toBlob((blob) => {
              resolve(blob);
            }, "image/jpeg");
          })
        );

        startY += height;
      }
    });

    input.style.display = "none";

    if (tab) {
      email = items.map((item) => item.value);
    } else {
      phone = items.map((item) => item.value);
    }

    const formData = new FormData();
    formData.append("documentId", id);
    formData.append("invoiceNumber", id);
    formData.append("phone", phone);
    formData.append("email", email);

    const pageBlobs = await Promise.all(pages);
    pageBlobs.forEach((blob, index) => {
      formData.append("pages", blob, `page${index}.jpeg`);
    });

    return formData;
  };

  /* 인풋 유효성 검사 */
  const isValidation = () => {
    let count = 0;

    items.forEach((item, i) => {
      const warm = !tab ? phoneValidation(item.value) : emailValidation(item.value);
      if (!warm) count++;
      setItems((prev) =>
        prev.map((item, index) => (i === index ? { ...item, warm: !warm } : item))
      );
    });

    return count === 0;
  };

  /* 전송하기 버튼 활성화 여부 체크 */
  const isDisabled = () => {
    const values = items.map((item) => item.value);

    return values.filter((value) => value.length === 0).length > 0;
  };

  /* validation 여부 체크 */
  const isWarm = () => {
    return items.filter((item) => item.warm).length > 0;
  };

  /* 탭 변경 시 인풋 초기화 */
  useEffect(() => {
    setItems([initInputValue]);
  }, [tab]);

  return (
    <>
      <GlobalHeader title={`${page} 전송`} left="닫기" onLeftClick={() => navigate(-1)} />
      <Root.Container>
        <Root.Header>
          <Root.Tab $color={!tab} onClick={() => setTab(false)}>
            번호로 전달
          </Root.Tab>
          <Root.Tab $color={!!tab} onClick={() => setTab(true)}>
            이메일로 전달
          </Root.Tab>
        </Root.Header>

        <Root.Content>
          <Root.Text>{!tab ? "전화번호" : "이메일"}</Root.Text>
          <Root.Box $warm={isWarm()}>
            {items.map((item, i) => (
              <Root.Wrap key={i}>
                <Root.Input
                  key={i}
                  $warm={item.warm}
                  type="text"
                  value={item.value}
                  onChange={(e) => onInputChange(e, i)}
                  placeholder={
                    !tab ? "‘-’ 제외 번호만 입력해 주세요." : "이메일 형식으로 입력해 주세요."
                  }
                />
                {item.value.length > 0 && (
                  <button type="button" onClick={() => onRemoveInput(i)}>
                    <img src={!item.warm ? image.close : image.redClose} alt="close" />
                  </button>
                )}
              </Root.Wrap>
            ))}
            {items.length < 2 && (
              <Root.AddButton onClick={onAddInput}>
                <img src={image.plus} alt="plus" />
                <p>{!tab ? "전화번호 추가" : "이메일 추가"}</p>
              </Root.AddButton>
            )}
          </Root.Box>
          {isWarm() && <Root.Warm>*내용을 올바르게 입력했는지 확인해 주세요.</Root.Warm>}
        </Root.Content>

        <Root.ButtonWrap>
          <Root.Button $color={true} onClick={() => navigate(`/preview/${domain}/${id}`)}>
            미리보기
          </Root.Button>
          <Root.Button disabled={!isDisabled()} onClick={onSubmit}>
            {loading ? <SyncLoader size={8} color={"#ffeecc"} /> : "전송하기"}
          </Root.Button>
        </Root.ButtonWrap>
      </Root.Container>
      <div style={{ display: "none" }} className="preview">
        {domain === "document" ? <WorkConfirm id={id} /> : <InvoicePage id={id} />}
      </div>
    </>
  );
}
