import GlobalHeader from "../../../layouts/GlobalHeader";
import { backConfirm, simpleAlert } from "../../../store/alert";
import ROUTES from "../../../router/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import InputWrap from "../../../components/wrap/input/InputWrap";
import Button from "../../../components/button/Button";
import { FindDiv } from "../styles";
import { useEffect, useState } from "react";
import useAuthCode from "../../../hook/useAuthCode";

export default function FindPassword() {
  const {
    email,
    setEmail,
    setPhone,
    phoneButton,
    phoneReadOnly,
    setCode,
    codeButton,
    codeReadOnly,
    success,
    formatTime,
    sendCode,
    checkCode
  } = useAuthCode();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setEmail(location.state?.email);
  }, []);

  useEffect(() => {
    setDisabled(!success);
  }, [success]);

  const result = () => {
    const response = true;

    if (response) {
      navigate(ROUTES.successPw, { state: { email: email } });
    } else {
      dispatch(simpleAlert("입력하신 정보와 일치하는 계정이 존재하지 않습니다."));
    }
  };

  return (
    <>
      <GlobalHeader
        title={"비밀번호 찾기"}
        left={"취소"}
        onLeftClick={() => dispatch(backConfirm(() => navigate(ROUTES.login)))}
      />

      <FindDiv>
        <InputWrap
          label={"아이디"}
          input={{
            type: "txt",
            placeholder: "아이디를 입력해 주세요.",
            value: email,
            onChange: (e) => setEmail(e)
          }}
          $margin={"0 0 32px 0"}
        />

        <InputWrap
          label={"전화번호 인증"}
          input={{
            type: "phone",
            width: "100%",
            placeholder: "'-' 제외 번호를 입력해 주세요.",
            maxLength: 11,
            mode: "numeric",
            onChange: (e) => setPhone(e)
          }}
          button={{
            type: "orange",
            width: "40%",
            height: "48px",
            text: "인증번호 발송",
            radius: "8px",
            fontSize: "16px",
            disabled: phoneButton,
            Click: sendCode,
            readOnly: phoneReadOnly
          }}
        />
        <InputWrap
          input={{
            type: "phone",
            placeholder: "인증번호 입력",
            onChange: (e) => setCode(e),
            readOnly: codeReadOnly,
            width: "100%",
            mode: "numeric",
            $option: formatTime()
          }}
          button={{
            type: "orange",
            width: "40%",
            height: "48px",
            text: "확인",
            radius: "8px",
            fontSize: "16px",
            disabled: codeButton,
            Click: checkCode
          }}
        />
        <Button
          type={"orange"}
          disabled={disabled}
          width={"100%"}
          height={"56px"}
          radius={"999px"}
          text={"다음"}
          fontSize={"18px"}
          $margin={"32px 0 0 0"}
          Click={result}
        />
      </FindDiv>
    </>
  );
}
