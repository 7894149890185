import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  isLock: false
};

export const guideModalSlice = createSlice({
  name: "guide-modal",
  initialState,
  reducers: {
    guideOpenModal: (state) => {
      document.body.style.overflow = "hidden";
      state.open = true;
    },
    guideCloseModal: (state) => {
      document.body.style.overflow = "auto";
      state.open = false;
      state.isLock = true;
    }
  }
});

export const guideModal = (state) => state.guide;

export const { guideOpenModal, guideCloseModal } = guideModalSlice.actions;

const guideModalReducer = guideModalSlice.reducer;

export default guideModalReducer;
