import styled from "styled-components";
import { useEffect, useState } from "react";
import { getDocumentDetail } from "../../../../api/document";
import moment from "moment/moment";
import { numberFormat } from "../../../../utils/numberFormat";
import { useNavigate } from "react-router-dom";
import { image } from "../../../../themes/theme";
import { useDispatch } from "react-redux";
import { simpleAlert } from "../../../../store/alert";

const Details = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px 40px 24px;
  `,
  Header: styled.div`
    height: 58px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #202020;
  `,
  Content: styled.div`
    background: #f9f9f9;
    width: 100%;
    padding: 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  Box: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  Wrap: styled.div`
    display: flex;
    gap: 24px;
  `,
  Text: styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #202020;
  `,
  Label: styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #909090;
    width: 40%;
    white-space: pre-wrap;
  `,
  ButtonWrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  `,
  Button: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    color: ${(props) => (props.$isColor ? "#E96C12" : "#FFFFFF")};
    background: ${(props) => (props.$isColor ? "#FFFFFF" : "#E96C12")};
    border-radius: 8px;
    border: ${(props) => props.$isColor && "1px solid #E96C12"};
    cursor: pointer;
  `,
  Sign: styled.img`
    width: 164px;
    height: 100px;
  `
};

export default function DocumentDetails({ documentId, close }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);

  // getDocumentDetail
  const documentDetail = async (documentId) => {
    try {
      const response = await getDocumentDetail(documentId.documentId);

      return response?.data?.data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    documentDetail(documentId).then((data) => {
      setItem(data);
    });
  }, [documentId]);

  const handleScroll = (e) => {
    e.stopPropagation();
  };

  console.log(item);

  return (
    <Details.Container className={"scrollable-content"} onScroll={handleScroll}>
      <Details.Header>작업확인서</Details.Header>
      <Details.Content>
        <Details.Text>기사 및 작업정보</Details.Text>
        <Details.Box>
          <Details.Wrap>
            <Details.Label>업체명</Details.Label>
            <Details.Text>{item?.company}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>기사명</Details.Label>
            <Details.Text>{item?.name}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>전화번호</Details.Label>
            <Details.Text>{item?.phone}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>작업내용</Details.Label>
            <Details.Text>{item?.content}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>작업시간</Details.Label>
            <Details.Text>
              {moment(`${item?.workDate} ${item?.startTime}`).format("HH:mm")}~
              {moment(`${item?.workDate} ${item?.endTime}`).format("HH:mm")}
            </Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>청구시간</Details.Label>
            <Details.Text>{item?.billingTime}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>작업날짜</Details.Label>
            <Details.Text>{item?.workDate}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>금액</Details.Label>
            <Details.Text>{numberFormat(item?.amount ?? 0)} 원</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>장비종류</Details.Label>
            <Details.Text>{item?.equipment.split("(")[0]}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>규격(톤)</Details.Label>
            <Details.Text>{item?.equipment.split("(")[1].replace(")", "")}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>작업자서명</Details.Label>
            <Details.Text>
              <Details.Sign src={image.document + item?.sign} alt={"작업자 사인"} />{" "}
            </Details.Text>
          </Details.Wrap>
        </Details.Box>
      </Details.Content>
      <Details.Content>
        <Details.Text>작업확인자 정보</Details.Text>
        <Details.Box>
          <Details.Wrap>
            <Details.Label>작업확인자</Details.Label>
            <Details.Text>{item?.checker}</Details.Text>
          </Details.Wrap>
          <Details.Wrap>
            <Details.Label>연락처</Details.Label>
            <Details.Text>{item?.checkerPhone}</Details.Text>
          </Details.Wrap>
          {item?.checkerSign.length > 0 ? (
            <Details.Wrap>
              <Details.Label>{`작업확인자\n서명`}</Details.Label>
              <Details.Text>
                <Details.Sign src={image.document + item?.checkerSign} alt={"확인자 사인"} />
              </Details.Text>
            </Details.Wrap>
          ) : null}
        </Details.Box>
      </Details.Content>

      <Details.ButtonWrap>
        <Details.Button
          $isColor={true}
          onClick={() => navigate(`/send/document/${item?.documentId}`)}
        >
          파일 전송
        </Details.Button>
        <Details.Button $isColor={false} onClick={close}>
          확인
        </Details.Button>
      </Details.ButtonWrap>
    </Details.Container>
  );
}
