import { client } from "./index";

const URLS = {
  notificationList: "/notification",
  notificationCount: "/notification/count"
};

export const getNotificationList = async () => {
  return await client.get(URLS.notificationList);
};

export const getNotificationListCount = async () => {
  return await client.get(URLS.notificationCount);
};

export const deleteNotification = async (uid) => {
  return await client.delete(URLS.notificationList + '/' + uid);
}