import styled from "styled-components";
import moment from "moment";
import Select from "../../../../components/select/Select";
import { openModal } from "../../../../store/modal";
import { useDispatch } from "react-redux";
import CalendarModal from "../../../../components/modal/CalendarModal";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
`;

const DateFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 328px;
  padding: 0 16px;
  color: #909090;
  gap: 12px;
`;

const DateSeletor = styled.div`
  width: 100%;
  height: 48px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-weight: 400;
  font-size: 15px;
  color: #444444;
  cursor: pointer;
`;

const SelectFilter = styled.div`
  width: 100%;
  min-width: 328px;
  padding: 0 16px;
`;

export default function SearchFilter({ search, setSearch, options }) {
  const dispatch = useDispatch();

  const onDateSelect = (key, value) => {
    dispatch(
      openModal(
        <CalendarModal
          value={value}
          onChange={(date) => setSearch((prev) => ({ ...prev, [key]: date }))}
        />
      )
    );
  };

  return (
    <Container>
      <DateFilter>
        <DateSeletor onClick={() => onDateSelect("startDate", search.startDate)}>
          {moment(search.startDate).format("YYYY.MM.DD")}
        </DateSeletor>
        ~
        <DateSeletor onClick={() => onDateSelect("endDate", search.endDate)}>
          {moment(search.endDate).format("YYYY.MM.DD")}
        </DateSeletor>
      </DateFilter>

      <SelectFilter>
        <Select
          width="100%"
          value={search?.company}
          option={options}
          readOnly={false}
          onChange={(company) => setSearch((prev) => ({ ...prev, company }))}
        />
      </SelectFilter>
    </Container>
  );
}
