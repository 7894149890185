import { useEffect, useState } from "react";

export default function usePasswordCheck() {

  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");

  const [passwordWarn, setPasswordWarn] = useState("");
  const [checkWarn, setCheckWarn] = useState("");

  useEffect(() => {
    if (checkPassword !== "") {
      if (password !== checkPassword) {
        setCheckWarn("비밀번호가 일치하지 않습니다.");
      } else {
        setCheckWarn("");
      }
    }
  }, [password, checkPassword]);

  const inputPassword = (e) => {

    setPassword(e);

    const reg1 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
    const reg2 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/;

    if (!reg1.test(e) && !reg2.test(e)) {
      setPasswordWarn("영문, 숫자 8-16 자리로 입력해 주세요.");
    } else {
      setPasswordWarn("");
    }
  };

  return {
    password,
    checkPassword,
    setCheckPassword,
    passwordWarn,
    checkWarn,
    inputPassword
  };

};