import styled from "styled-components";

export const FindDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  padding: 16px;
`;

export const SuccessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-top: 50px;
`;

export const SuccessText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  color: rgba(106, 106, 106, 1);
`;

export const IdDiv = styled.div`
  width: 100%;
  padding: 40px 32px;
  margin-top: 48px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  background: rgba(249, 249, 249, 1);
`;

export const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
  font-size: 16px;
  color: rgba(68, 68, 68, 1);
`;

export const IdSpan = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: rgba(233, 108, 18, 1);
`;