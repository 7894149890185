import styled from "styled-components";
import moment from "moment";
import { numberFormat } from "../../../../utils/numberFormat";
import { useState } from "react";
import BottomSheet from "../../../../components/BottomSheet";
import DocumentDetails from "../../../Documents/components/DocumentDetails";

const List = {
  Container: styled.div`
    border-top: 1px solid #e1e1e1;
    width: 100%;
  `,
  Empty: styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  `,
  ItemBox: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Item: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    height: 70px;
    cursor: pointer;
    background: #FFFFFF;
    border-bottom: 1px solid #E9E9E9;
  `,
  Left: styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
  `,
  Date: styled.div`
    font-size: 14px;
    font-weight: 400;
  `,
  TitleBox: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  Title: styled.div`
    font-size: 15px;
    color: #202020;
  `,
  Content: styled.div`
    font-size: 14px;
    color: #444444;
  `,
  Right: styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
  `,
  State: styled.div`
    //color: #e96c12;
    color: ${(props) =>
      props.status === "전송됨" ? "#E96C12" : props.status === "미확인" ? "#909090" : "#202020"};
    font-size: 14px;
  `,
  AmountBox: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 2px;
    font-size: 15px;
    font-weight: 500;

    & span {
      font-weight: 400;
    }
  `,
  TimeBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;
    color: #909090;
  `,
  Time: styled.div`
    font-size: 14px;
    font-weight: 400;
  `
};

export default function DocumentHistoryList({ items }) {
  const [selector, setSelector] = useState(null);

  return (
    <>
      <List.Container>
        {items?.length === 0 && (
          <List.Empty>
            등록된 작업확인서가 없습니다.
          </List.Empty>
        )}
        {items?.length > 0 && (
          <List.ItemBox>
            {items?.map((item, i) => (
              <List.Item
                key={i}
                onClick={() => setSelector(item)}
              >
                <List.Left>
                  <List.Date>{moment(item.workDate).format("MM.DD")}</List.Date>
                  <List.TitleBox>
                    <List.Title>{item.company}</List.Title>
                    <List.Content>* {item.content}</List.Content>
                  </List.TitleBox>
                </List.Left>
                <List.Right>
                  <List.State status={item.status}>{item.status}</List.State>
                  <List.TitleBox>
                    <List.AmountBox>
                      {numberFormat(item.amount)}
                      <span>원</span>
                    </List.AmountBox>
                    <List.TimeBox>
                      <List.Time>
                        {moment(`${item.workDate} ${item.startDate}`).format("HH:mm")}
                      </List.Time>
                      ~
                      <List.Time>
                        {moment(`${item.workDate} ${item.endDate}`).format("HH:mm")}
                      </List.Time>
                    </List.TimeBox>
                  </List.TitleBox>
                </List.Right>
              </List.Item>
            ))}
          </List.ItemBox>
        )}
      </List.Container>
      <BottomSheet
        isOpen={!!selector}
        close={() => setSelector(null)}
        children={<DocumentDetails documentId={selector} close={() => setSelector(null)} />}
      />
    </>
  );
}
