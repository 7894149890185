import {
  EquipmentAddButton,
  EquipmentAddDiv,
  EquipmentButtonDiv,
  EquipmentCancelButton,
  EquipmentDiv,
  EquipmentWrap,
  LineDiv
} from "./styles";
import Label from "../label/Label";
import Button from "../button/Button";
import EquipmentData from "./EquipmentData";
import SelectWrap from "../wrap/select/SelectWrap";
import InputWrap from "../wrap/input/InputWrap";
import { useEffect, useState } from "react";
import { addEquipment, getEquipment } from "../../api/user";

export default function Equipment(props) {
  const { list, updateList } = props;

  const [sizeReadOnly, setSizeReadOnly] = useState(true);
  const [size, setSize] = useState("");
  const [equipment, setEquipment] = useState("");
  const [equipmentList, setEquipmentList] = useState(null);
  const [equipmentOption, setEquipmentOption] = useState("");
  const [unit, setUnit] = useState("");

  const [showAdd, setShowAdd] = useState(false);

  const [warn, setWarn] = useState("");

  const equipmentData = async () => {
    try {
      const response = await getEquipment();

      setEquipmentList(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    equipmentData();
  }, []);

  const changeEquipment = (e) => {
    const unit = equipmentList.find((item) => item.name === e).unit;
    setWarn("");
    setEquipment(e);
    setSizeReadOnly(false);
    setUnit(unit);
    setEquipmentOption("(" + unit + ")");
  };

  const handleAddButtonClick = () => {
    setShowAdd(true);
  };

  const handleCancelButtonClick = () => {
    setShowAdd(false);
  };

  const insertEquipment = async () => {
    setWarn("");

    if (!equipment || !size) {
      setWarn("내용을 올바르게 입력했는지 확인해 주세요.");
    } else {
      const request = {
        equip: equipment,
        size: size,
        unit: unit
      };

      try {
        await addEquipment(request);
        handleCancelButtonClick();
        updateList();
        setEquipment("");
        setSize("");
        setUnit("");
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <EquipmentWrap>
        <Label text={"장비 종류 및 규격"} />

        <EquipmentDiv>
          {list.map((item) => (
            <EquipmentData
              key={item.id}
              text={item.equipment}
              value={item.id}
              isSingle={list.length === 1}
              updateList={updateList}
            />
          ))}
          {showAdd && (
            <EquipmentAddDiv>
              <div style={{ padding: "0 12px" }}>
                <SelectWrap
                  label={"장비 및 규격 추가"}
                  select={{
                    width: "100%",
                    $warn: warn,
                    option: equipmentList
                      ? equipmentList.map((item) => ({
                          label: item.name,
                          value: item.name
                        }))
                      : [],
                    onChange: (e) => changeEquipment(e)
                  }}
                />
                <InputWrap
                  input={{
                    type: "float",
                    placeholder: "숫자만 입력해 주세요",
                    $option: equipmentOption,
                    readOnly: sizeReadOnly,
                    $warn: warn,
                    mode: "numeric",
                    onChange: (e) => setSize(e)
                  }}
                />
              </div>

              <EquipmentButtonDiv>
                <EquipmentCancelButton onClick={handleCancelButtonClick}>
                  취소
                </EquipmentCancelButton>
                <EquipmentAddButton onClick={insertEquipment}>추가</EquipmentAddButton>
              </EquipmentButtonDiv>
            </EquipmentAddDiv>
          )}
        </EquipmentDiv>

        {!showAdd && list.length < 5 && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button
              type={"line"}
              text={"장비 및 규격 추가"}
              width={"40%"}
              height={"48px"}
              radius={"999px"}
              Click={handleAddButtonClick}
            />
          </div>
        )}

        <LineDiv />
      </EquipmentWrap>
    </>
  );
}
