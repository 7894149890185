import styled from "styled-components";
import Calendar from "react-calendar";

export const CalendarModal = styled(Calendar)`
  /* 캘린더 */
  & .react-calendar {
    width: 100%;
    max-width: 328px;
    height: 400px;
    background: #ffff;
    border: none;
    padding: 8px 0;
  }

  & .react-calendar__navigation button:disabled {
    background: #ffffff;
    color: #202020;
  }

  /* 달력 년/월 표시 글씨 커스텀 */
  & .react-calendar__navigation__label > span {
    font-size: 16px;
    font-weight: 500;
  }

  /*요일 section 커스텀 하기*/
  & .react-calendar__month-view__weekdays {
    /*월,화,수... 글자 부분*/

    & abbr {
      color: #444444;
      font-size: 18px;
      font-weight: 500;
    }
  }

  & abbr[title] {
    text-decoration: none;
  }

  /*day 타일 한개 한개 모양 커스텀하기*/
  & .react-calendar__tile {
    font-size: 18px;
    font-weight: 400;
    width: 46px;
    height: 46px;
    color: #000000;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: visible !important;
  }

  /* day 타일 hover, focus 시 모양 커스텀 */
  & .react-calendar__tile:enabled:hover,
  & .react-calendar__tile:enabled:focus {
    background-color: #e96c12;
    border-radius: 999px;
    color: #ffffff;
  }

  /* 현재 날짜 */
  & .react-calendar__tile--now {
    background: #ffffff;
    color: #000000;
    border-radius: 999px;
  }

  /* 날짜 선택 됐을 때 day 타일 커스텀하기 */
  & .react-calendar__tile--active {
    background: #e96c12;
    color: #ffffff;
    border-radius: 999px;
  }

  & .react-calendar__tile--active:enabled:hover,
  & .react-calendar__tile--active:enabled:focus {
    background: #e96c12;
  }

  /* 토,일 색상 변경 */
  & .react-calendar__month-view__days__day--weekend {
    //color: #ff5e5e;
  }

  /* 날짜 이동 버튼 */
  & .react-calendar__navigation button {
    font-size: 20px;
    color: #202020;
  }
`;

export const Dot = {
  Circle: styled.div`
    position: absolute;
    top: 42px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #2fd30f;
    border: 1px solid white;
  `
};

export const MemoForm = {
  Container: styled.div`
    padding: 12px 8px;
  `,
  Header: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
  `,
  DayForm: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #f9f9f9;
    border-radius: 12px;
  `,
  Day: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    position: relative;

    & p {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #909090;
    }

    & p.active {
      color: #ffffff;
      background: #e96c12;
      border-radius: 999px;
    }
  `,
  Circle: styled.div`
    position: absolute;
    bottom: -3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #2fd30f;
    border: 1px solid white;
  `,
  MemoContainer: styled.div`
    border: 1px solid #e1e1e1;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    height: 230px;
    margin-top: 12px;
    position: relative;
    overflow: hidden;
    padding: 16px;

    & h1 {
      color: #909090;
    }

    & textarea {
      width: 100%;
      height: 150px;
      outline: none;
      resize: none;
      font-size: 15px;
      color: #444444;
      line-height: 22px;
      margin-top: 8px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & span {
      position: absolute;
      bottom: 4px;
      right: 8px;
      color: #909090;
    }
  `,
  ButtonCont: styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  `,
  Button: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.active ? "#e96c12" : "#E9E9E9")};
    color: ${(props) => (props.active ? "#ffffff" : "#444444")};
    cursor: pointer;
  `
};
