import Button from "../../button/Button";
import { WrapDiv } from "./styles";

export default function ButtonWrap(props) {
  const {
    button1,
    button2
  } = props;

  return (
    <>
      <WrapDiv>
        <Button
          {...button1}
        />
        <Button
          {...button2}
        />
      </WrapDiv>
    </>
  );

}