import styled from "styled-components";

export const WorkDiv = styled.div`
    width: 794px;
    height: 1123px;
    padding: 0 24px;
    border: 2px solid #000000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: #ffffff;
`;

export const WorkTitle = styled.h1`
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    margin-top: 50px;
    letter-spacing: 7px;
`;

export const WorkHeader = styled.div`
    width: 100%;
    margin-top: 70px;
    display: flex;
    align-items: center;

    & > span {
        display: flex;
        align-items: center;
        width: 20%;
        height: 38px;
        padding: 0 16px;
        border: 1px solid #000000;
    }
`;

export const WorkHeaderContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 38px;
    padding: 0 16px;
    border: 1px solid #000000;
    border-left: 0;
    font-size: 18px;
`;

export const WorkTable = styled.div`
    width: 100%;
    margin-top: 50px;
`;

export const WorkTableH = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    letter-spacing: 4px;
    font-size: 18px;

    white-space: nowrap;
    background: #D7D7D7;
    border: 1px solid #202020;
`;

export const WorkTableR = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    & span {
        display: flex;
        align-items: center;
        height: ${(props) => props.height || "34px"};
        padding: 0 14px;
        border: 1px solid #000000;
        border-top: 0;
        border-left: 0;
    }

    & span:first-child {
        border-left: 1px solid #000000;
    }
`;

export const WorkTableRH = styled.span`
    width: ${(props) => props.width || '18%'};
    letter-spacing: ${(props) => props.letterSpacing || '4px'};
    font-size: ${(props) => props.fontSize || 'normal'};
`;

export const WorkTableRD = styled.span`
    width: ${(props) => props.width || "32%"};
    justify-content: ${(props) => props.$justify || "flex-start"};
`;

export const WorkTableCol = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    //& > div span:first-child {
    //    width: 30%;
    //}
    //& > div span:last-child {
    //    width: 70%;
    //}
`;

export const WorkTableColS = styled.div`
    width: 50%;
    display: flex;
    align-items: center;

    & > span {
        height: 68px;
    }
    //
    //& > span:first-child {
    //    width: 30%;
    //    border-left: 0;
    //}
    //& > span:last-child {
    //    width: 70%;
    //}
`;

export const WorkSign = styled.img`
    height: 100%;
`;
