import GlobalHeader from "../../layouts/GlobalHeader";
import { FindDiv, LoginDiv } from "./styles";
import InputWrap from "../../components/wrap/input/InputWrap";
import Button from "../../components/button/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../router/routes";
import { login } from "../../store/user";
import { useDispatch } from "react-redux";
import { simpleAlert } from "../../store/alert";
import { signIn } from "../../api/user";

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  const validateInput = () => {
    return email.trim().length > 0 && password.trim().length > 0;
  };

  useEffect(() => {
    setDisabled(!validateInput());
  }, [email, password]);

  const result = async () => {
    const request = {
      email: email,
      password: password
    };

    try {
      const response = await signIn(request);

      dispatch(
        login({
          loginData: response.data.data.accessToken
        })
      );

      navigate(ROUTES.main);
    } catch (e) {
      let code = e?.response?.data?.code;
      if (code === "A01") {
        dispatch(simpleAlert("관리자가 정지한 회원입니다."));
      } else {
        dispatch(simpleAlert("아이디, 비밀번호를 확인해 주세요."));
      }
    }
  };

  return (
    <>
      <GlobalHeader title="로그인" />

      <LoginDiv>
        <InputWrap
          label={"아이디"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "아이디를 입력해 주세요.",
            $option: "reset",
            mode: "email",
            onChange: (e) => setEmail(e)
          }}
        />
        <InputWrap
          label={"비밀번호"}
          input={{
            width: "100%",
            type: "password",
            placeholder: "비밀번호를 입력해 주세요.",
            $option: "password",
            onChange: (e) => setPassword(e)
          }}
        />
        <Button
          type={"orange"}
          disabled={disabled}
          width={"100%"}
          height={"56px"}
          radius={"999px"}
          text={"로그인"}
          fontSize={"18px"}
          $margin={"8px 0 0 0"}
          Click={result}
        />
      </LoginDiv>

      <FindDiv>
        <button type={"button"} onClick={() => navigate(ROUTES.findId)}>
          아이디 찾기
        </button>
        <span>|</span>
        <button type={"button"} onClick={() => navigate(ROUTES.findPw)}>
          비밀번호 찾기
        </button>
      </FindDiv>
    </>
  );
}
