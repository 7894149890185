import GlobalHeader from "../../layouts/GlobalHeader";
import styled from "styled-components";
import InputWrap from "../../components/wrap/input/InputWrap";
import { useEffect, useState } from "react";
import SelectWrap from "../../components/wrap/select/SelectWrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ROUTES from "../../router/routes";
import { image } from "../../themes/theme";
import { openModal } from "../../store/modal";
import SignatureModal from "../../components/modal/SignatureModal";
import { useDispatch } from "react-redux";
import { phoneValidation } from "../../utils/validation";
import { userInfo } from "../../api/user";
import { numberFormat } from "../../utils/numberFormat";
import moment from "moment";
import CalendarModal from "../../components/modal/CalendarModal";
import Input from "../../components/input/Input";
import RadioBox from "../../components/RadioBox";
import {
  createAdminSign,
  createDocuments,
  getCompanies,
  getDocumentDetail,
  rejectDocument
} from "../../api/document";
import { backConfirm, setAlert, setConfirm, simpleAlert } from "../../store/alert";
import { SyncLoader } from "react-spinners";
import { ConfigProvider, TimePicker } from "antd";
import dayjs from "dayjs";

const Root = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 24px 16px;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  BorderContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 12px;
  `,
  Title: styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #202020;
  `,
  Text: styled.div`
    font-size: 16px;
    color: rgba(32, 32, 32, 1);
  `,
  TimeWrap: styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
  `,
  TimeLabel: styled.span`
    color: #909090;
    width: 10%;
  `,
  Div: styled.div`
    width: 100%;
  `,
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  `,
  Box: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
  `,
  SignBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.background};
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    height: 289px;
    white-space: pre-wrap;
    font-size: 16px;
    color: #444444;
    cursor: pointer;
    overflow: hidden;

    & img {
    }
  `,
  AgreeBox: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    & p {
      color: #202020;
    }

    & span {
      color: #e96c12;
    }
  `,
  Button: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background: ${(props) => (props.disabled ? "#d7d7d7" : "#E96C12")};
    border-radius: 999px;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 80px;
  `
};

const initData = {
  name: "",
  phone: "",
  company: "",
  workDate: "",
  startTime: "",
  endTime: "",
  billingTime: "일대",
  content: "",
  amount: "",
  checker: "",
  checkerPhone: "",
  memo: "",
  equipment: "",
  sign: "",
  agree: false
};

export default function CreateDocumentPage() {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);
  const [adminSign, setAdminSign] = useState(null);
  const [companiesData, setCompaniesData] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [isCompanyAutoInput, setIsCompanyAutoInput] = useState(false);

  const [isContentsAutoInput, setIsContentsAutoInput] = useState(false);
  const [selectContents, setSelectContents] = useState(null);

  const [isCheckerAutoInput, setIsCheckerAutoInput] = useState(false);
  const [selectCheckers, setSelectCheckers] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* setData */
  const onChange = (name, value, isAutoComplete) => {
    setData((prev) => ({ ...prev, [name]: value }));

    if (isAutoComplete === true) {
      const selectedCompanyData = companiesData.find((companyData) => companyData[name] === value);

      if (selectedCompanyData && selectedCompanyData.company !== "직접입력") {
        // setData((prev) => ({
        //   ...prev,
        //   ...selectedCompanyData
        // }));

        setSelectContents(["직접입력", ...selectedCompanyData.documentContents]);
        setSelectCheckers([{ checker: "직접입력" }, ...selectedCompanyData.documentChecker]);
      }
    }
  };

  /* 업체 조회 */
  const getCompanyList = async () => {
    try {
      const response = await getCompanies();
      const data = response?.data?.data;

      const updatedData = [...data, { company: "직접입력" }];

      setCompaniesData(updatedData);
    } catch (e) {}
  };

  /* 맴버 조회 후 기사명 & 전화번호 매핑 */
  const getMember = async () => {
    try {
      const response = await userInfo();
      const data = response.data.data[0];
      setData((prev) => ({
        ...prev,
        name: data?.name,
        phone: data?.phone?.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
        bizName: data?.bizName,
        equipment: data?.equipmentList[0]?.equipment,
        equipmentList: data?.equipmentList
      }));
    } catch (e) {
      if (location.pathname === "/documents/create") {
        navigate(ROUTES.login);
      }
    }
  };

  /* 작업확인서 상세 정보 */
  const getDocument = async () => {
    try {
      const response = await getDocumentDetail(documentId);
      const responseData = response?.data?.data;
      const equipmentList = [
        {
          equipment: responseData.equipment
        }
      ];

      setData((prev) => ({
        ...prev,
        ...responseData,
        equipmentList: equipmentList
      }));
      setReadOnly(true);
    } catch (e) {
      navigate(ROUTES.login);
    }
  };

  /* 작업확인서 데이터 유효성 체크 */
  const isDisabled = () => {
    let isValid = false;
    for (const key of Object.keys(data)) {
      if (["memo", "startTime", "endTime"].includes(key)) {
        continue;
      }
      if (!data[key]) {
        isValid = true;
        break;
      }
      if (key === "checkerPhone" && !phoneValidation(data[key])) {
        isValid = true;
        break;
      }
    }
    return isValid;
  };

  /* 작업확인서 등록 */
  const onCreate = async () => {
    setLoading(true);
    try {
      await createDocuments(data);
      dispatch(
        setAlert({
          message: `작업확인자에게 작업확인서가\n정상적으로 전달 되었습니다.`,
          onClick: () => navigate(ROUTES.main)
        })
      );
    } catch (e) {
      console.log("작업확인서 등록 실패 : ", e);
      dispatch(simpleAlert("작업확인서 등록을 실패하였습니다."));
    } finally {
      setLoading(false);
    }
  };

  /* 관리자 사인 전송 */
  const onAdminSign = async () => {
    setLoading(true);
    try {
      await createAdminSign(documentId, adminSign);
      dispatch(
        setAlert({
          message: `작업 내용 확인 서명이\n정상적으로 전달 되었습니다.`,
          onClick: () => navigate(ROUTES.successDocument)
        })
      );
    } catch (e) {
      console.log("작업 내용 확인 서명 전달 실패 : ", e);
      dispatch(simpleAlert("작업 내용 확인 서명 전달 실패하였습니다."));
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => dispatch(backConfirm(() => navigate(ROUTES.main)));

  const handleReject = () => {
    dispatch(
      setConfirm({
        message: "작업확인서를 반려 하시겠습니까?",
        onClick: reject
      })
    );
  };

  const reject = async () => {
    try {
      await rejectDocument(documentId);

      dispatch(
        setAlert({
          message: "작업확인서가 정상적으로 반려 되었습니다.",
          onClick: () => navigate(ROUTES.main)
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const setDefaultDate = () => {
    setData((prev) => ({ ...prev, workDate: moment(new Date()).format("YYYY-MM-DD") }));
  };

  useEffect(() => {
    getMember();
    setDefaultDate();
    getCompanyList();
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBack);
    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, []);

  useEffect(() => {
    if (documentId) {
      getDocument();
    }
  }, [documentId]);

  const [isStartPickerOpen, setStartIsPickerOpen] = useState(false);
  const [isEndPickerOpen, setEndIsPickerOpen] = useState(false);

  const selectTime = (key, val) => {
    if (val) {
      const hour = String(val.$H).padStart(2, "0");
      const minute = String(val.$m).padStart(2, "0");
      const time = hour + ":" + minute + ":00";

      onChange(key, time);
    }
  };

  const pickerOpenHandler = (type, isOpen) => {
    if (type === "start") {
      setStartIsPickerOpen(isOpen);
    }
    if (type === "end") {
      setEndIsPickerOpen(isOpen);
    }
  };

  useEffect(() => {
    if (isStartPickerOpen || isEndPickerOpen) {
      document.body.style.overflow = "hidden";
    }
    if (!isStartPickerOpen && !isEndPickerOpen) {
      document.body.style.overflow = "auto";
    }
  }, [isStartPickerOpen, isEndPickerOpen]);

  return (
    <>
      <GlobalHeader
        title={"작업확인서 작성"}
        left="닫기"
        onLeftClick={handleBack}
        right={location.pathname === "/documents/create" ? "" : "반려"}
        rightColor={"#FF0B0B"}
        onRightClick={handleReject}
      />
      <Root.Container>
        <Root.Content>
          <Root.Title>내 정보를 확인해주세요.</Root.Title>
          <Root.BorderContent>
            <InputWrap
              label={"기사명"}
              input={{
                width: "100%",
                type: "text",
                value: data?.name,
                readOnly: true
              }}
            />
            <InputWrap
              label={"전화번호"}
              input={{
                width: "100%",
                type: "text",
                value: data?.phone,
                readOnly: true
              }}
            />
          </Root.BorderContent>
        </Root.Content>
        <Root.Content>
          <Root.Title>전달할 내용을 입력해 주세요.</Root.Title>
          {
            // !companiesData
            //   ?.filter((item) => item.company !== "직접입력")
            //   ?.map((item) => item.company)
            //   .includes(data?.company) ? (
            isCompanyAutoInput ? (
              <InputWrap
                label={"업체명"}
                input={{
                  placeholder: "새로운 업체명을 입력해주세요.",
                  width: "100%",
                  type: "text",
                  value: data?.company === "직접입력" ? "" : data?.company || "",
                  readOnly: readOnly,
                  onChange: (value) => onChange("company", value, true)
                }}
              />
            ) : (
              <SelectWrap
                label={"업체명"}
                select={{
                  width: "100%",
                  value: data?.company ? data?.company : "",
                  option: Array.isArray(companiesData)
                    ? companiesData.map((c) => ({
                        label: c.company,
                        value: c.company
                      }))
                    : [],
                  onChange: (value) => {
                    if (value === "직접입력") {
                      setIsCompanyAutoInput(true);
                      return onChange("company", "");
                    }
                    onChange("company", value, true);
                  }
                }}
              />
            )
          }
          <InputWrap
            label={"작업 날짜 선택"}
            input={{
              width: "100%",
              type: "text",
              name: "workDate",
              value: data?.workDate,
              placeholder: moment(new Date()).format("YYYY-MM-DD") + " (오늘)",
              onClick: () => {
                if (!readOnly) {
                  dispatch(
                    openModal(
                      <CalendarModal
                        onChange={(value) =>
                          onChange("workDate", moment(value).format("YYYY-MM-DD"))
                        }
                      />
                    )
                  );
                }
              },
              readOnly: true
            }}
          />
          <Root.Wrap>
            <Root.Text>작업 시간 (선택 사항)</Root.Text>
            <Root.Box>
              <ConfigProvider
                theme={{
                  token: {
                    controlHeight: 48,
                    controlItemBgActive: "#FFEECC",
                    colorPrimary: "#E96C12",
                    colorTextPlaceholder: "#757575",
                    fontSize: 16,
                    borderRadius: 8
                  },
                  components: {
                    DatePicker: {
                      activeBorderColor: "rgba(32, 32, 32, 1)",
                      hoverBorderColor: "rgba(32, 32, 32, 1)"
                    }
                  }
                }}
              >
                <Root.TimeWrap>
                  <Root.TimeLabel>시작</Root.TimeLabel>
                  <Root.Div>
                    <TimePicker
                      defaultValue={dayjs("00:00:00", "HH:mm")}
                      open={isStartPickerOpen}
                      style={{ width: "100%" }}
                      format={"HH:mm"}
                      minuteStep={1}
                      value={!data?.startTime ? "" : dayjs(data?.startTime, "HH:mm")}
                      placeholder={"선택"}
                      showNow={false}
                      inputReadOnly={true}
                      disabled={readOnly}
                      allowClear={false}
                      onChange={(val) => selectTime("startTime", val)}
                      onOpenChange={(isOpen) => pickerOpenHandler("start", isOpen)}
                    ></TimePicker>
                  </Root.Div>
                </Root.TimeWrap>
                <Root.TimeWrap>
                  <Root.TimeLabel>종료</Root.TimeLabel>
                  <Root.Div>
                    <TimePicker
                      defaultValue={dayjs("00:00:00", "HH:mm")}
                      open={isEndPickerOpen}
                      style={{ width: "100%" }}
                      format={"HH:mm"}
                      minuteStep={1}
                      value={!data?.endTime ? "" : dayjs(data?.endTime, "HH:mm")}
                      placeholder={"선택"}
                      showNow={false}
                      inputReadOnly={true}
                      disabled={readOnly}
                      allowClear={false}
                      onChange={(val) => selectTime("endTime", val)}
                      onOpenChange={(isOpen) => pickerOpenHandler("end", isOpen)}
                    ></TimePicker>
                  </Root.Div>
                </Root.TimeWrap>
              </ConfigProvider>
            </Root.Box>
          </Root.Wrap>
          <Root.Wrap>
            <Root.Text>청구 시간</Root.Text>
            <Root.Box>
              <RadioBox
                title="일대"
                isCheck={data?.billingTime === "일대"}
                onClick={() => {
                  if (!readOnly) {
                    onChange("billingTime", "일대");
                  }
                }}
              />
              <RadioBox
                title="반나절"
                isCheck={data?.billingTime === "반나절"}
                onClick={() => {
                  if (!readOnly) {
                    onChange("billingTime", "반나절");
                  }
                }}
              />
              <RadioBox
                title="직접 입력"
                isCheck={!["일대", "반나절"].includes(data?.billingTime)}
                onClick={() => {
                  if (!readOnly) {
                    onChange("billingTime", "직접 입력");
                  }
                }}
              />
            </Root.Box>
            {!["일대", "반나절"].includes(data?.billingTime) && (
              <Input
                width="100%"
                type="phone"
                mode="numeric"
                name="billingTime"
                placeholder="내용을 입력해주세요."
                value={data?.billingTime}
                readOnly={readOnly}
                onChange={(value) => onChange("billingTime", value)}
                $option="시간"
              />
            )}
          </Root.Wrap>
          {isContentsAutoInput ? (
            <InputWrap
              label={"작업 내용"}
              input={{
                width: "100%",
                type: "text",
                name: "content",
                value: data?.content,
                placeholder: "내용을 입력해 주세요.",
                readOnly: readOnly,
                onChange: (value) => onChange("content", value)
              }}
            />
          ) : (
            <SelectWrap
              label={"작업 내용"}
              select={{
                width: "100%",
                value: data?.content ? data?.content : "",
                option: Array.isArray(selectContents)
                  ? selectContents?.map((content) => ({
                      label: content,
                      value: content
                    }))
                  : [
                      {
                        label: "직접입력",
                        value: "직접입력"
                      }
                    ],
                onChange: (value) => {
                  if (value === "직접입력") {
                    setIsContentsAutoInput(true);
                    return onChange("content", "");
                  }
                  onChange("content", value, true);
                }
              }}
            />
          )}

          <InputWrap
            label={"금액"}
            input={{
              width: "100%",
              type: "phone",
              name: "amount",
              value: data?.amount ? numberFormat(data.amount) : "",
              placeholder: "금액을 입력해 주세요.",
              mode: "numeric",
              readOnly: readOnly,
              onChange: (value) => onChange("amount", value),
              $option: "원"
            }}
          />
          {isCheckerAutoInput ? (
            <InputWrap
              label={"작업확인자 이름"}
              input={{
                width: "100%",
                type: "text",
                name: "checker",
                value: data?.checker,
                placeholder: "작업확인자 이름을 입력해 주세요.",
                readOnly: readOnly,
                onChange: (value) => onChange("checker", value)
              }}
            />
          ) : (
            <SelectWrap
              label={"작업확인자 이름"}
              select={{
                width: "100%",
                value: data?.checker ? data?.checker : "",
                option: Array.isArray(selectCheckers)
                  ? selectCheckers.map((item) => ({
                      label: item.checker,
                      value: item.checker
                    }))
                  : [
                      {
                        label: "직접입력",
                        value: "직접입력"
                      }
                    ],
                onChange: (value) => {
                  if (value === "직접입력") {
                    setIsCheckerAutoInput(true);
                    return onChange("checker", "");
                  }
                  onChange("checker", value, true);

                  const checker = selectCheckers.find((item) => item.checker === value);
                  onChange("checkerPhone", checker.phone);
                }
              }}
            />
          )}
          <InputWrap
            label={"작업확인자 휴대폰 번호"}
            input={{
              width: "100%",
              type: "phone",
              name: "checkerPhone",
              value: data?.checkerPhone,
              placeholder: "‘-’ 제외 번호를 입력해 주세요.",
              maxLength: "11",
              mode: "numeric",
              readOnly: readOnly,
              onChange: (value) => onChange("checkerPhone", value)
            }}
          />
          <InputWrap
            label={"비고 (선택 사항)"}
            input={{
              width: "100%",
              type: "text",
              name: "memo",
              value: data?.memo,
              placeholder: "내용을 입력해 주세요.",
              readOnly: readOnly,
              onChange: (value) => onChange("memo", value)
            }}
          />
          <SelectWrap
            label={"장비 종류 및 규격"}
            select={{
              width: "100%",
              value: data?.equipment
                ? data?.equipment
                : data?.equipmentList && data?.equipmentList.length > 0
                  ? data?.equipmentList[0].equipment
                  : "",
              option: data?.equipmentList
                ? data?.equipmentList.map((item) => ({
                    label: item.equipment,
                    value: item.equipment
                  }))
                : [],
              readOnly: readOnly,
              onChange: (value) => onChange("equipment", value)
            }}
          />
          <Root.Wrap>
            <Root.Text>기사 서명</Root.Text>
            {data?.sign ? (
              <Root.SignBox
                background="#ffffff"
                onClick={() =>
                  location.pathname === "/documents/create"
                    ? dispatch(
                        openModal(<SignatureModal onChange={(file) => onChange("sign", file)} />)
                      )
                    : {}
                }
              >
                <img
                  src={
                    typeof data?.sign === "string"
                      ? image.document + data?.sign
                      : URL.createObjectURL(data?.sign)
                  }
                  alt={"sign"}
                />
              </Root.SignBox>
            ) : (
              <Root.SignBox
                background="#ffeecc"
                onClick={() =>
                  dispatch(
                    openModal(<SignatureModal onChange={(file) => onChange("sign", file)} />)
                  )
                }
              >{`해당 영역을 터치하여\n서명을 진행해 주세요.`}</Root.SignBox>
            )}
          </Root.Wrap>
          {documentId && (
            <Root.Wrap>
              <Root.Text>작업확인자 서명</Root.Text>
              {adminSign ? (
                <Root.SignBox
                  background="#ffffff"
                  onClick={() =>
                    dispatch(openModal(<SignatureModal onChange={(file) => setAdminSign(file)} />))
                  }
                >
                  <img
                    src={
                      typeof adminSign === "string"
                        ? image.document + adminSign
                        : URL.createObjectURL(adminSign)
                    }
                    alt={"adminSign"}
                  />
                </Root.SignBox>
              ) : (
                <Root.SignBox
                  background="#ffeecc"
                  onClick={() =>
                    dispatch(openModal(<SignatureModal onChange={(file) => setAdminSign(file)} />))
                  }
                >{`해당 영역을 터치하여\n서명을 진행해 주세요.`}</Root.SignBox>
              )}
            </Root.Wrap>
          )}
          {!documentId && (
            <Root.AgreeBox onClick={() => onChange("agree", !data?.agree)}>
              <img src={data.agree ? image.checkbox : image.noneCheckbox} />
              <p>건설기계 표준계약서에 동의합니다.</p>
            </Root.AgreeBox>
          )}
        </Root.Content>
        {!documentId ? (
          <Root.Button disabled={isDisabled()} onClick={onCreate}>
            {loading ? <SyncLoader size={8} color={"#ffeecc"} /> : "발송하기"}
          </Root.Button>
        ) : (
          <Root.Button disabled={!adminSign} onClick={onAdminSign}>
            {loading ? <SyncLoader size={8} color={"#ffeecc"} /> : "전송하기"}
          </Root.Button>
        )}
      </Root.Container>
    </>
  );
}
