import { useEffect, useState } from "react";
import { getBizCategory, getBizStatus, getEquipment } from "../api/user";

export default function useBizInfo() {

  const [bizNumber, setBizNumber] = useState("");
  const [bizName, setBizName] = useState("");
  const [ceo, setCeo] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [bizStatus, setBizStatus] = useState("");
  const [bizCategory, setBizCategory] = useState("");
  const [equipment, setEquipment] = useState("");
  const [size, setSize] = useState("");

  const [bizStatusList, setBizStatusList] = useState(null);
  const [bizCategoryList, setBizCategoryList] = useState(null);
  const [equipmentList, setEquipmentList] = useState(null);
  const [equipmentOption, setEquipmentOption] = useState("");
  const [unit, setUnit] = useState("");
  const [sizeReadOnly, setSizeReadOnly] = useState(true);

  const bizStatusData = async () => {
    try {
      const response = await getBizStatus();

      setBizStatusList(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const bizCategoryData = async () => {
    try {
      const response = await getBizCategory();

      setBizCategoryList(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const equipmentData = async () => {
    try {
      const response = await getEquipment();

      setEquipmentList(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const changeEquipment = (e) => {
    const unit = equipmentList.find(item => item.name === e).unit;
    setEquipment(e);
    setUnit(unit);
    setSizeReadOnly(false);
    setEquipmentOption("(" + unit + ")");
  };

  useEffect(() => {
    bizStatusData();
    bizCategoryData();
    equipmentData();
  }, []);

  return {
    bizNumber,
    setBizNumber,
    bizName,
    setBizName,
    ceo,
    setCeo,
    address,
    setAddress,
    addressDetail,
    setAddressDetail,
    bizStatus,
    setBizStatus,
    bizCategory,
    setBizCategory,
    equipment,
    size,
    setSize,
    bizStatusList,
    bizCategoryList,
    equipmentList,
    equipmentOption,
    unit,
    sizeReadOnly,
    changeEquipment
  };

};