import GlobalHeader from "../../../layouts/GlobalHeader";
import { backConfirm, setAlert, simpleAlert } from "../../../store/alert";
import ROUTES from "../../../router/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BizLink, ChangeButtonDiv, MyDiv } from "../styles";
import InputWrap from "../../../components/wrap/input/InputWrap";
import ButtonWrap from "../../../components/wrap/button/ButtonWrap";
import React, { useEffect } from "react";
import { openModal } from "../../../store/modal";
import DaumPost from "../../../components/modal/DaumPost";
import SelectWrap from "../../../components/wrap/select/SelectWrap";
import useBizInfo from "../../../hook/useBizInfo";
import { getInfo, updateBiz } from "../../../api/user";

export default function ChangeBiz() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    bizNumber,
    setBizNumber,
    bizName,
    setBizName,
    ceo,
    setCeo,
    address,
    setAddress,
    addressDetail,
    setAddressDetail,
    bizStatus,
    setBizStatus,
    bizCategory,
    setBizCategory,
    bizStatusList,
    bizCategoryList
  } = useBizInfo();

  const exit = () => {
    dispatch(backConfirm(() => navigate(ROUTES.my)));
  };

  const getUser = async () => {
    try {
      const response = await getInfo();
      const data = response.data.data;

      setBizNumber(data.bizNumber);
      setBizName(data.bizName);
      setCeo(data.bizCeo);
      setAddress(data.bizAddress);
      setAddressDetail(data.bizAddressDetail);
      setBizStatus(data.bizStatus);
      setBizCategory(data.bizCategory);
    } catch (e) {
      navigate(ROUTES.login);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const change = async () => {
    if (
      !bizNumber ||
      !bizName ||
      !ceo ||
      !address ||
      !addressDetail ||
      !bizStatus ||
      !bizCategory
    ) {
      dispatch(simpleAlert("모든 정보를 입력해 주세요."));
    } else {
      const request = {
        bizNumber: bizNumber,
        bizName: bizName,
        bizCeo: ceo,
        bizAddress: address,
        bizAddressDetail: addressDetail,
        bizStatus: bizStatus,
        bizCategory: bizCategory
      };

      try {
        await updateBiz(request);

        dispatch(
          setAlert({
            message: "사업자정보가 변경되었습니다.",
            onClick: () => navigate(ROUTES.my)
          })
        );
      } catch (e) {
        dispatch(simpleAlert(e.response.data.message));
      }
    }
  };

  return (
    <>
      <GlobalHeader
        title={"사업자정보 수정"}
        left={"닫기"}
        onLeftClick={() => exit()}
      ></GlobalHeader>

      <MyDiv style={{ paddingBottom: "112px" }}>
        <InputWrap
          label={"사업자 등록번호"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "'-' 제외 번호를 입력해 주세요.",
            value: bizNumber,
            onChange: (e) => setBizNumber(e),
            maxLength: 10
          }}
        />
        <InputWrap
          label={"상호명"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "상호명을 입력해 주세요.",
            value: bizName,
            onChange: (e) => setBizName(e)
          }}
        />
        <InputWrap
          label={"사업자 대표명"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "사업자 대표명을 입력해 주세요.",
            value: ceo,
            onChange: (e) => setCeo(e)
          }}
        />
        <div>
          <InputWrap
            label={"사업자 소재지"}
            input={{
              type: "text",
              value: address,
              placeholder: "도로명주소",
              width: "100%",
              readOnly: true
            }}
            button={{
              type: "orange",
              width: "40%",
              height: "48px",
              text: "우편번호",
              radius: "8px",
              fontSize: "16px",
              Click: () => dispatch(openModal(<DaumPost onChange={setAddress} />))
            }}
          />
          <InputWrap
            input={{
              type: "text",
              placeholder: "상세주소를 입력해 주세요.",
              value: addressDetail,
              onChange: (e) => setAddressDetail(e)
            }}
          />
        </div>
        <SelectWrap
          label={"업태"}
          select={{
            width: "100%",
            value: bizStatus,
            option: bizStatusList
              ? bizStatusList.map((item) => ({
                  label: item.name,
                  value: item.name
                }))
              : [],
            onChange: (e) => setBizStatus(e)
          }}
        />
        <SelectWrap
          label={"업종"}
          select={{
            width: "100%",
            value: bizCategory,
            option: bizCategoryList
              ? bizCategoryList.map((item) => ({
                  label: item.name,
                  value: item.name
                }))
              : [],
            onChange: (e) => setBizCategory(e)
          }}
        />

        <BizLink to={"/my/expire"}>회원탈퇴</BizLink>
      </MyDiv>

      <ChangeButtonDiv>
        <ButtonWrap
          button1={{
            type: "black",
            disable: true,
            width: "100%",
            height: "56px",
            text: "취소",
            radius: "8px",
            fontSize: "18px",
            color: "rgba(68, 68, 68, 1)",
            $back: "#F9F9F9",
            $border: "#F9F9F9",
            Click: () => exit()
          }}
          button2={{
            type: "orange",
            disable: true,
            width: "100%",
            height: "56px",
            text: "변경",
            radius: "8px",
            fontSize: "18px",
            Click: () => change()
          }}
        />
      </ChangeButtonDiv>
    </>
  );
}
