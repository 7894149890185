import { StyledPopup } from "./styles";
import { IoCloseOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { getPopup } from "../../api/set";

export default function Popup() {
  const [show, setShow] = useState(false);
  const [popupId, setPopupId] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    const checkPopup = async () => {
      let neverShowList = localStorage.getItem("neverShowPopupList");
      neverShowList = neverShowList ? JSON.parse(neverShowList) : [];

      if (!neverShowList.includes(popupId)) {
        await getData();
      }
    };
    checkPopup();
  }, [popupId]);

  const getData = async () => {
    try {
      const response = await getPopup();
      if (response.data && response.data.data) {
        const data = response.data.data;
        let neverShowList = localStorage.getItem("neverShowPopupList");
        neverShowList = neverShowList ? JSON.parse(neverShowList) : [];

        if (!neverShowList.includes(data.id)) {
          setShow(true);
          setPopupId(data.id);
          setTitle(data.title);
          setImage(data.imagePath);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const neverShow = () => {
    let neverShowList = localStorage.getItem("neverShowPopupList");
    neverShowList = neverShowList ? JSON.parse(neverShowList) : [];

    if (!neverShowList.includes(popupId)) {
      neverShowList.push(popupId);
      localStorage.setItem("neverShowPopupList", JSON.stringify(neverShowList));
    }
    setShow(false);
  };

  return (
    <StyledPopup.Portal $show={show}>
      <StyledPopup.Fade></StyledPopup.Fade>
      <StyledPopup.Root $show={show}>
        <StyledPopup.Header>
          <StyledPopup.Title>{title}</StyledPopup.Title>
          <StyledPopup.Close onClick={() => setShow(false)}>
            <IoCloseOutline size={"32px"} color={"#6B6B6B"}></IoCloseOutline>
          </StyledPopup.Close>
        </StyledPopup.Header>

        <StyledPopup.Content>
          <img src={image} alt={"팝업"} />
        </StyledPopup.Content>

        <StyledPopup.Footer>
          <StyledPopup.Never onClick={neverShow}>다시 보지 않기</StyledPopup.Never>
        </StyledPopup.Footer>
      </StyledPopup.Root>
    </StyledPopup.Portal>
  );
}
