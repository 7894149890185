import { Radio } from "../Icon";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    & p {
        color: #202020;
    }
`;

export default function RadioBox({ title, isCheck, onClick }) {
    return (
        <Container onClick={onClick}>
            <Radio isCheck={isCheck} />
            <p>{title}</p>
        </Container>
    );
}
