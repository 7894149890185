import GlobalHeader from "../../../layouts/GlobalHeader";
import { useDispatch } from "react-redux";
import { backConfirm, simpleAlert } from "../../../store/alert";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../router/routes";
import InputWrap from "../../../components/wrap/input/InputWrap";
import { FindDiv } from "../styles";
import { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import useAuthCode from "../../../hook/useAuthCode";
import { findId } from "../../../api/user";
import dayjs from "dayjs";

export default function FindId() {
  const {
    phone,
    setPhone,
    phoneButton,
    phoneReadOnly,
    setCode,
    codeButton,
    codeReadOnly,
    success,
    formatTime,
    sendCode,
    checkCode
  } = useAuthCode();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!success);
  }, [success]);

  const result = async () => {
    try {
      const response = await findId({ phone: phone });
      const data = response.data.data;

      navigate(ROUTES.successId, {
        state: {
          email: data.email,
          createdAt: dayjs(data.createdAt).format("YYYY.MM.DD")
        }
      });
    } catch (e) {
      dispatch(simpleAlert("등록된 계정이 없습니다."));
    }
  };

  return (
    <>
      <GlobalHeader
        title={"아이디 찾기"}
        left={"취소"}
        onLeftClick={() => dispatch(backConfirm(() => navigate(ROUTES.login)))}
      />

      <FindDiv>
        <InputWrap
          label={"전화번호 인증"}
          input={{
            type: "phone",
            placeholder: "'-' 제외 번호를 입력해 주세요.",
            onChange: (e) => setPhone(e),
            readOnly: phoneReadOnly,
            width: "100%",
            mode: "numeric",
            maxLength: 11
          }}
          button={{
            type: "orange",
            width: "40%",
            height: "48px",
            text: "인증번호 발송",
            radius: "8px",
            fontSize: "16px",
            disabled: phoneButton,
            Click: sendCode
          }}
        />
        <InputWrap
          input={{
            type: "phone",
            placeholder: "인증번호 입력",
            onChange: (e) => setCode(e),
            readOnly: codeReadOnly,
            $option: formatTime(),
            width: "100%",
            mode: "numeric",
            maxLength: 6
          }}
          button={{
            type: "orange",
            width: "40%",
            height: "48px",
            text: "확인",
            radius: "8px",
            fontSize: "16px",
            disabled: codeButton,
            Click: checkCode
          }}
        />
        <Button
          type={"orange"}
          disabled={disabled}
          width={"100%"}
          height={"56px"}
          radius={"999px"}
          text={"아이디 찾기"}
          fontSize={"18px"}
          $margin={"32px 0 0 0"}
          Click={result}
        />
      </FindDiv>
    </>
  );
}
