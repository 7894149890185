import React, { useEffect, useState } from "react";
import ROUTES from "../../router/routes";
import { image } from "../../themes/theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const SubmitBtn = styled.div`
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);

  padding: 12px 16px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: #e96c12;
  cursor: pointer;

  & p {
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
  }
`;

const MainFixedButton = () => {
  const navigate = useNavigate();

  return (
    <SubmitBtn onClick={() => navigate(ROUTES.createDocument)}>
      <img src={image.write} alt="write" />
      <p>작업확인서 작성</p>
    </SubmitBtn>
  );
};

export default MainFixedButton;
