import React, { useEffect, useState } from "react";
import { OptionButton, OptionDiv, PreDiv, StyledInput, WarnMessage } from "./styles";
import { AiOutlineClose, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function Input(props) {
  const {
    type,
    onChange,
    readOnly,
    width,
    value,
    placeholder,
    $warn,
    $option,
    $pre,
    maxLength,
    name,
    onClick,
    mode
  } = props;

  const [inputValue, setInputValue] = useState(value || "");
  const [visible, setVisible] = useState(value);
  const [color, setColor] = useState("rgba(225, 225, 225, 1)");

  const noValue = inputValue === "" || inputValue === undefined;

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  useEffect(() => {
    if (!!$warn) {
      setColor("rgba(255, 11, 11, 1)");
    } else if (noValue) {
      setColor("rgba(225, 225, 225, 1)");
    } else {
      setColor("rgba(32, 32, 32, 1)");
    }
  }, [$warn, inputValue]);

  const changeValue = (e) => {
    let val = e.target.value;

    if (type === "phone") {
      val = val.replace(/[^0-9]/g, "");
    }

    if (type === "float") {
      val = val.replace(/[^0-9.]/g, "");
    }

    setInputValue(val);

    if (onChange) {
      onChange(val);
    }
  };

  const clickReset = () => {
    setInputValue("");
    if (onChange) {
      onChange("");
    }
  };

  const handelChangeVisible = () => {
    setVisible((prev) => !prev);
  };

  const renderOption = () => {
    if ($option === "password") {
      return (
        <OptionButton type={"button"} onClick={handelChangeVisible}>
          {visible ? (
            <AiOutlineEyeInvisible size={"24px"} color={color} />
          ) : (
            <AiOutlineEye size={"24px"} color={color} />
          )}
        </OptionButton>
      );
    } else if ($option === "reset") {
      return (
        <OptionButton type={"button"} onClick={clickReset}>
          {noValue ? "" : <AiOutlineClose size={"24px"} color={color} />}
        </OptionButton>
      );
    } else if (!!$option) {
      return <OptionDiv>{$option}</OptionDiv>;
    }
  };
  const renderInput = () => {
    if (!!$option) {
      return (
        <div style={{ position: "relative", width: width }}>
          {!!$pre ? <PreDiv>{$pre}</PreDiv> : ""}
          <StyledInput
            type={type === "password" && !visible ? "password" : "text"}
            readOnly={readOnly}
            width={"100%"}
            value={inputValue}
            placeholder={placeholder}
            $warn={!!$warn}
            onChange={changeValue}
            $padding={"50px"}
            $hasValue={noValue}
            $pre={$pre}
            maxLength={maxLength}
            name={name}
            onClick={onClick}
            inputMode={!mode ? "text" : mode}
          />
          {renderOption()}
        </div>
      );
    } else {
      return (
        <StyledInput
          type={type === "password" && !visible ? "password" : "text"}
          readOnly={readOnly}
          width={width}
          value={inputValue}
          placeholder={placeholder}
          $warn={!!$warn}
          onChange={changeValue}
          $hasValue={noValue}
          $pre={$pre}
          maxLength={maxLength}
          name={name}
          onClick={onClick}
          inputMode={mode}
        />
      );
    }
  };

  return (
    <>
      {renderInput()}
      {$warn && <WarnMessage>*{$warn}</WarnMessage>}
    </>
  );
}
