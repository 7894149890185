import styled from "styled-components";
import { image } from "../../themes/theme";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const ContentWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 240px;
  height: 96.5px;
  gap: 24px;
`;

const Logo = styled.div``;

const Text = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

export default function SuccessDocument() {
  return (
    <Wrap>
      <ContentWrap>
        <Logo>
          <img src={image.logo} alt="logo" width={170} height={50} />
        </Logo>
        <Text>서명 완료 및 전송되었습니다.</Text>
      </ContentWrap>
    </Wrap>
  );
}
