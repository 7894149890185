import styled from "styled-components";
import { numberFormat } from "../../../../utils/numberFormat";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
`;

const Box = styled.div`
  width: 100%;
  height: 74px;
  background: #f9f9f9;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  & p {
    font-size: 16px;
    color: #444444;
  }
  & div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    & span {
      color: #e96c12;
      font-size: 18px;
    }
    & span:last-child {
      font-size: 16px;
    }
  }
`;

export default function TotalAmount({ amount }) {
  return (
    <Container>
      <Box>
        <p>합계</p>
        <div>
          <span>{numberFormat(amount)}</span>
          <span>원</span>
        </div>
      </Box>
    </Container>
  );
}
