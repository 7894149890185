import {
  WorkDiv,
  WorkHeader,
  WorkHeaderContent,
  WorkSign,
  WorkTable,
  WorkTableCol,
  WorkTableColS,
  WorkTableH,
  WorkTableR,
  WorkTableRD,
  WorkTableRH,
  WorkTitle
} from "./styles";
import { useEffect, useState } from "react";
import { getDocumentDetail } from "../../api/document";
import dayjs from "dayjs";
import { numberFormat, phoneFormat } from "../../utils/numberFormat";
import { image } from "../../themes/theme";

function Header({ company, date }) {
  return (
    <>
      <WorkHeader>
        <span style={{ letterSpacing: '4px', width: '18%', fontSize: '18px' }}>업체명</span>
        <WorkHeaderContent>
          <span>{company}</span>
          <span>{date}</span>
        </WorkHeaderContent>
      </WorkHeader>
    </>
  );
}

function Row({ title1, data1, title2, data2 }) {
  return (
    <>
      <WorkTableR>
        <WorkTableRH>{title1}</WorkTableRH>
        <WorkTableRD>{data1}</WorkTableRD>
        <WorkTableRH>{title2}</WorkTableRH>
        <WorkTableRD>{data2}</WorkTableRD>
      </WorkTableR>
    </>
  );
}

function Row2({ title1, data1, title2, data2 }) {
  return (
    <>
      <WorkTableR height={'38px'}>
        <WorkTableRH fontSize={'18px'} letterSpacing={'2px'}>{title1}</WorkTableRH>
        <WorkTableRD fontSize={'18px'}>{data1}</WorkTableRD>
        <WorkTableRH fontSize={'18px'}>{title2}</WorkTableRH>
        <WorkTableRD fontSize={'18px'}>{data2}</WorkTableRD>
      </WorkTableR>
    </>
  );
}

function SignRow({ amount, note, sign }) {
  return (
    <>
      <WorkTableR>
        <WorkTableCol>
          <WorkTableR>
            <span style={{ width: '36%', letterSpacing: '4px' }}>금액</span>
            <span style={{ width: '64%', justifyContent: 'flex-start'}}>{amount}</span>
          </WorkTableR>
          <WorkTableR>
            <span style={{ width: "36%", letterSpacing: "4px"}}>비고</span>
            <span style={{ width: "64%", justifyContent: "flex-start"}}>{note}</span>
          </WorkTableR>
        </WorkTableCol>
        <WorkTableColS>
          <span style={{ width: "36%", letterSpacing: "4px", borderLeft: '0'}}>서명</span>
          <WorkTableRD $justify={"center"} width={"64%"}>
            <WorkSign src={sign ? sign + "?" : ""} crossOrigin="anonymous" />
          </WorkTableRD>
        </WorkTableColS>
      </WorkTableR>
    </>
  );
}

function DataRow(props) {
  const {
    bizName,
    workTime,
    content,
    billingTime,
    equipment,
    name,
    size,
    phone,
    amount,
    note,
    sign
  } = props;

  return (
    <>
      <Row title1={"상호명"} data1={bizName} title2={"작업시간"} data2={workTime} />

      <Row title1={"작업내용"} data1={content} title2={"청구시간"} data2={billingTime} />

      <Row title1={"장비명"} data1={equipment} title2={"기사명"} data2={name} />

      <Row title1={"규격"} data1={size} title2={"연락처"} data2={phone} />

      <SignRow amount={amount} note={note} sign={sign} />
    </>
  );
}

function ConfirmRow({ name, phone, sign }) {
  return (
    <>
      <Row2 title1={"작업확인자"} data1={name} title2={"연락처"} data2={phone} />

      <WorkTableR height={"150px"}>
        <WorkTableRH fontSize={'18px'}>서명</WorkTableRH>
        <WorkTableRD width={"82%"} $justify={"center"}>
          <WorkSign src={sign ? sign + "?" : ""} crossOrigin="anonymous" />
        </WorkTableRD>
      </WorkTableR>
    </>
  );
}

export default function WorkConfirm({ id }) {
  const [company, setCompany] = useState("");
  const [date, setDate] = useState("");

  const [bizName, setBizName] = useState("");
  const [workTime, setWorkTime] = useState("");
  const [content, setContent] = useState("");
  const [billingTime, setBillingTime] = useState("");
  const [equipment, setEquipment] = useState("");
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [sign, setSign] = useState("");

  const [checker, setChecker] = useState("");
  const [checkerPhone, setCheckerPhone] = useState("");
  const [checkerSign, setCheckerSign] = useState("");

  const getDetail = async () => {
    try {
      const response = await getDocumentDetail(id);
      const data = response?.data?.data;

      const equip = data.equipment.split("(");
      const start = dayjs(`${data.workDate} ${data.startTime}`).format("HH:mm");
      const end = dayjs(`${data.workDate} ${data.endTime}`).format("HH:mm");

      setCompany(data.company);
      setDate(dayjs(data.workDate).format("YYYY년 MM월 DD일"));
      setName(data.name);
      setPhone(phoneFormat(data.phone));
      setBizName(data.bizName);
      setAmount(numberFormat(data.amount) + " 원");
      setEquipment(equip[0]);
      setSize(equip[1].replace(")", ""));
      setWorkTime(start + " ~ " + end);
      setBillingTime(data.billingTime);
      setContent(data.content);
      setNote(data.memo);
      setSign(image.document + data.sign);
      setChecker(data.checker);
      setCheckerPhone(phoneFormat(data.checkerPhone));
      setCheckerSign(!data.checkerSign ? "" : image.document + data.checkerSign);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <WorkDiv>
        <WorkTitle>작업확인서</WorkTitle>
        <Header company={company} date={date} />

        <WorkTable>
          <WorkTableH>기사</WorkTableH>

          <DataRow
            bizName={bizName}
            workTime={workTime}
            content={content}
            billingTime={billingTime}
            equipment={equipment}
            name={name}
            size={size}
            phone={phone}
            amount={amount}
            note={note}
            sign={sign}
          />
        </WorkTable>

        <WorkTable>
          <WorkTableH>작업확인자</WorkTableH>
          <ConfirmRow name={checker} phone={checkerPhone} sign={checkerSign} />
        </WorkTable>
      </WorkDiv>
    </>
  );
}
