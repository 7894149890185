import styled from "styled-components";
import { colors } from "../../themes/theme";

export const Dialog = {
  Root: styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    background: ${colors.dialogBg};
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Cont: styled.div`
    width: 280px;
    height: auto;
    overflow: hidden;
    border-radius: 8px;
    background: ${colors.white};
  `,
  Info: styled.div`
    width: 100%;
    height: auto;
    padding: 24px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 15px;
    line-height: 27px;
    color: #444444;
    white-space: pre-wrap;
    text-align: center;
  `,
  BtnCont: styled.div`
    width: 100%;
    display: flex;
  `,
  Btn: styled.div`
    width: 100%;
    height: 48px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E96C12;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
  `,
  CancelBtn: styled.div`
    width: 100%;
    height: 48px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    color: #444444;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
  `
};
