import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dialog } from "./styles";
import { alert, closeAlert, setAlert, simpleAlert } from "../../store/alert";

const Alert = () => {
  const dispatch = useDispatch();
  const { message, onClick, isConfirm, isBack } = useSelector(alert);

  const alertRef = useRef(null);

  const alertOutSideClick = (e) => {

    if (onClick) return;

    if (alertRef.current === e.target) {
      cancel();
    }
  };

  const escKeyModalClose = (e) => {
    if (onClick) return;
    if (e.key === "Escape") {
      cancel();
    }
  };

  const onEvent = () => {
    cancel();
    onClick();
  };

  const cancel = () => {
    dispatch(closeAlert());
  };

  useEffect(() => {
    window.addEventListener("keydown", escKeyModalClose);
    return () => window.removeEventListener("keydown", escKeyModalClose);
  }, [setAlert, simpleAlert]);

  return (
    <Dialog.Root ref={alertRef} onClick={(e) => alertOutSideClick(e)}>
      <Dialog.Cont>
        <Dialog.Info>{message}</Dialog.Info>
        {
          !isConfirm
            ? <Dialog.Btn onClick={onClick ? onEvent : cancel}>
              확인
            </Dialog.Btn>
            : <Dialog.BtnCont>
              <Dialog.CancelBtn onClick={isBack ? onEvent : cancel}>
                {isBack ? "나가기" : "취소"}
              </Dialog.CancelBtn>
              <Dialog.Btn onClick={isBack ? cancel : onEvent}>
                {isBack ? "아니요(유지)" : "확인"}
              </Dialog.Btn>
            </Dialog.BtnCont>
        }
      </Dialog.Cont>
    </Dialog.Root>
  );
};

export default Alert;
