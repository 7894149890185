import { client } from "./index";
import moment from "moment";

const URLS = {
    note: "/member/note"
};

export const getNoteList = async () => {
    return await client.get(URLS.note);
};

export const getNoteDetails = async (noteId) => {
    return await client.get(URLS.note + "/" + noteId);
};

export const addNote = async (noteDate, content) => {
    return await client.post(URLS.note, {
        noteDate: moment(noteDate).format("YYYY-MM-DD"),
        content
    });
};

export const changeNote = async (params) => {
    return await client.patch(URLS.note, params);
};
