import styled from "styled-components";

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  padding: 0 16px;
  position: relative;
  background: #ffffff;

  & h1 {
    font-size: 20px;
  }
`;

const LeftBtn = styled.div`
  position: absolute;
  left: 16px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const RightBtn = styled.div`
  position: absolute;
  right: 16px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: ${(props) => (props.$color ? props.$color : "#202020")};
`;

export default function GlobalHeader({
  title,
  left,
  onLeftClick,
  right,
  rightColor,
  rightIcon,
  onRightClick
}) {
  return (
    <Header>
      <LeftBtn onClick={onLeftClick}>{left}</LeftBtn>
      <h1>{title}</h1>
      <RightBtn onClick={onRightClick} $color={rightColor}>
        {rightIcon ? <img src={rightIcon} alt="rigthicon" /> : right}
      </RightBtn>
    </Header>
  );
}
