import React, { useEffect, useState } from "react";
import {
  InvoiceDiv,
  InvoiceHeader,
  InvoiceHeaderFrom,
  InvoiceHeaderTo,
  InvoiceSubtitle,
  InvoiceTable,
  InvoiceTableF,
  InvoiceTableFD, InvoiceTableFDSecond,
  InvoiceTableH,
  InvoiceTableHD,
  InvoiceTableR,
  InvoiceTableRD,
  InvoiceTitle,
  InvoiceTotal
} from "./styles";
import { bizNumberFormat, numberFormat, phoneFormat } from "../../utils/numberFormat";
import { getInvoiceDetail } from "../../api/document";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../router/routes";

function PageHeader(props) {
  const { date, company, ceo, phone, bizName, bizNumber, bizStatus, bizCategory, address } = props;

  return (
    <InvoiceHeader>
      <InvoiceHeaderTo>
        <span className={"date"}>{date}</span>
        <span className={"company"}>{company} 귀하</span>
      </InvoiceHeaderTo>

      <InvoiceHeaderFrom>
        <div className={"label"}>공급자정보</div>

        <div className={"ceo"}>대표</div>

        <div className={"info"}>
          <div className={"row"}>
            <span>{ceo}</span>
            <span>{phone}</span>
          </div>
          <div className={"row"}>
            <span>{bizName}</span>
            <span>{bizNumberFormat(bizNumber)}</span>
          </div>
          <div className={"row"}>
            <span>{bizStatus}</span>
            <span>{bizCategory}</span>
          </div>
          <span>{address}</span>
        </div>
      </InvoiceHeaderFrom>
    </InvoiceHeader>
  );
}

function Header() {
  return (
    <InvoiceTableH className="header">
      <InvoiceTableHD>일자</InvoiceTableHD>
      <InvoiceTableHD>품목</InvoiceTableHD>
      <InvoiceTableHD letterSpacing='2px'>규격 (톤)</InvoiceTableHD>
      <InvoiceTableHD>시간</InvoiceTableHD>
      <InvoiceTableHD letterSpacing='2px' width="17.5%">공급가액</InvoiceTableHD>
      <InvoiceTableHD width="17.5%">세액</InvoiceTableHD>
      <InvoiceTableHD>비고</InvoiceTableHD>
    </InvoiceTableH>
  );
}

function Data(props) {
  const { date, size, time, price, vat, note } = props;

  return (
    <>
      <InvoiceTableR>
        <InvoiceTableRD>{date}</InvoiceTableRD>
        <InvoiceTableRD>장비사용료</InvoiceTableRD>
        <InvoiceTableRD>{size}</InvoiceTableRD>
        <InvoiceTableRD>{time}</InvoiceTableRD>
        <InvoiceTableRD justifyContent={'flex-end'} width="17.5%">{price}</InvoiceTableRD>
        <InvoiceTableRD justifyContent={'flex-end'} width="17.5%">{vat}</InvoiceTableRD>
        <InvoiceTableRD>{note}</InvoiceTableRD>
      </InvoiceTableR>
    </>
  );
}

function InvoiceData({ data }) {
  return (
    <>
      {data.map((el, index) => (
        <Data
          key={index}
          date={dayjs(el.worDate).format("YYYY.MM.DD")}
          size={el.standard}
          time={el.billingTime}
          price={numberFormat(el.amount)}
          vat={numberFormat(el.tax)}
          note={el.equipment}
        />
      ))}
    </>
  );
}

function Footer(props) {
  const { totalPrice, totalVat } = props;

  return (
    <>
      <InvoiceTableF>
        <InvoiceTableFDSecond width="26%">계</InvoiceTableFDSecond>
        <InvoiceTableFDSecond></InvoiceTableFDSecond>
        <InvoiceTableFDSecond></InvoiceTableFDSecond>
        <InvoiceTableFDSecond justifyContent={'flex-end'} width="17.5%">{numberFormat(totalPrice)}</InvoiceTableFDSecond>
        <InvoiceTableFDSecond justifyContent={'flex-end'} width="17.5%">{numberFormat(totalVat)}</InvoiceTableFDSecond>
        <InvoiceTableFDSecond></InvoiceTableFDSecond>
      </InvoiceTableF>
      <InvoiceTableF>
        <InvoiceTableFDSecond $weight={"600"} width="26%">
          총합계
        </InvoiceTableFDSecond>
        <InvoiceTableFDSecond></InvoiceTableFDSecond>
        <InvoiceTableFDSecond></InvoiceTableFDSecond>
        <InvoiceTableFDSecond width="35%" justifyContent={'flex-end'}>{numberFormat(totalPrice + totalVat)}</InvoiceTableFDSecond>
        <InvoiceTableFDSecond></InvoiceTableFDSecond>
      </InvoiceTableF>
    </>
  );
}

// const row = [];

export default function InvoicePage({ id }) {
  const navigate = useNavigate();

  const [date, setDate] = useState("");
  const [company, setCompany] = useState("");
  const [ceo, setCeo] = useState("");
  const [phone, setPhone] = useState("");
  const [bizName, setBizName] = useState("");
  const [bizNumber, setBizNumber] = useState("");
  const [bizStatus, setBizStatus] = useState("");
  const [bizCategory, setBizCategory] = useState("");
  const [address, setAddress] = useState("");
  const [row, setRow] = useState([]);

  const InvoiceDetail = async () => {
    try {
      const response = await getInvoiceDetail(id);
      const data = response.data.data;

      setDate(dayjs(data.invoiceDate).format("YYYY년 MM월 DD일"));
      setCompany(data.company);
      setCeo(data.bizCeo);
      setPhone(phoneFormat(data.bizPhone));
      setBizName(data.bizName);
      setBizNumber(data.bizNumber);
      setBizStatus(data.bizStatus);
      setBizCategory(data.bizCategory);
      setAddress(data.bizAddress);
      setRow(data.invoiceDocuments);
    } catch (e) {
      navigate(ROUTES.main);
    }
  };

  useEffect(() => {
    InvoiceDetail();
  }, []);

  const firstData = row.slice(0, 18);
  const remainingData = row.slice(18);

  const otherPageData = [];
  for (let i = 0; i < remainingData.length; i += 27) {
    otherPageData.push(remainingData.slice(i, i + 27));
  }

  const { totalPrice, totalVat } = row.reduce(
    (totals, el) => {
      return {
        totalPrice: totals.totalPrice + el.amount,
        totalVat: totals.totalVat + el.tax
      };
    },
    { totalPrice: 0, totalVat: 0 }
  );

  return (
    <>
      <InvoiceDiv>
        <InvoiceTitle>거래명세서</InvoiceTitle>

        <PageHeader
          date={date}
          company={company}
          ceo={ceo}
          phone={phone}
          bizName={bizName}
          bizNumber={bizNumber}
          bizStatus={bizStatus}
          bizCategory={bizCategory}
          address={address}
        />

        <InvoiceTotal>
          <span className={"label"}>합계금액</span>
          <span className={"price"}>{numberFormat(totalPrice + totalVat)} &nbsp; 원정</span>
        </InvoiceTotal>

        <InvoiceTable>
          <Header />
          <InvoiceData data={firstData} />

          {firstData.length <= 17 && <Footer totalPrice={totalPrice} totalVat={totalVat} />}
        </InvoiceTable>
      </InvoiceDiv>

      {firstData.length === 18 && otherPageData.length === 0 && (
        <InvoiceDiv>
          <InvoiceTable>
            <Footer totalPrice={totalPrice} totalVat={totalVat} />
          </InvoiceTable>
        </InvoiceDiv>
      )}

      {otherPageData.map((pageData, pageIndex) => {
        const isLastPage = pageIndex === otherPageData.length - 1;
        const isExtraPageNeeded = isLastPage && pageData.length >= 26;

        return (
          <InvoiceDiv key={pageIndex}>
            <InvoiceTitle>거래명세서</InvoiceTitle>
            <InvoiceSubtitle>{pageIndex + 2}page</InvoiceSubtitle>

            <InvoiceTable>
              <Header />
              <InvoiceData data={pageData} />

              {isLastPage && !isExtraPageNeeded && (
                <Footer totalPrice={totalPrice} totalVat={totalVat} />
              )}
            </InvoiceTable>
          </InvoiceDiv>
        );
      })}

      {otherPageData.length > 0 && otherPageData[otherPageData.length - 1].length >= 26 && (
        <InvoiceDiv>
          <InvoiceTable>
            <Footer totalPrice={totalPrice} totalVat={totalVat} />
          </InvoiceTable>
        </InvoiceDiv>
      )}
    </>
  );
}
