const ROUTES = {
  splash: "/",
  login: "/login",
  join: "/join",
  sns: "/join/sns",
  findId: "/find/id",
  successId: "/find/id/success",
  findPw: "/find/password",
  successPw: "/find/password/success",
  customerWithdraw: "/customer/withdraw",
  my: "/my",
  invoice: "/invoice",
  main: "/main",
  documents: "/documents",
  documentHistory: "/document/history",
  send: "/send/:domain/:id",
  preview: "/preview/:domain/:id",
  createDocument: "/documents/create",
  successDocument: "/documents/sign/success",
  signDocument: "/documents/sign/:documentId",
  policy: "/policy/:type",
  changePw: "/my/pw",
  changePhone: "/my/phone",
  changeBiz: "/my/biz",
  oAuth2Callback: "login/auth/callback",
  expireMember: "/my/expire"
};

export default ROUTES;
