import { Arrow, StyledSelect } from "./styles";
import React, { useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

export default function Select(props) {
  const { readOnly, width, value, option, onChange, $warn } = props;

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const noValue = inputValue === "" || inputValue === undefined;

  const changeValue = (e) => {
    let val = e.target.value;
    setInputValue(val);

    if (onChange) {
      onChange(val);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledSelect
        type={"search"}
        width={width}
        disabled={readOnly}
        $hasValue={noValue}
        $warn={!!$warn}
        placeholder={"선택"}
        value={inputValue}
        onChange={changeValue}
      >
        <option value={""} hidden={true}>
          선택
        </option>
        {option &&
          option.map((el, index) => (
            <option key={index} value={el.value}>
              {el.label}
            </option>
          ))}
      </StyledSelect>
      <Arrow>
        <RiArrowDownSLine size={"24px"} color={"rgba(107, 107, 107, 1)"} />
      </Arrow>
    </div>
  );
}
