import { useState } from "react";

export default function useEmailCheck() {

  const [email, setEmail] = useState("");
  const [emailWarn, setEmailWarn] = useState("");

  const inputEmail = (e) => {

    setEmail(e);

    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!reg.test(e)) {
      setEmailWarn("이메일 형식으로 입력해 주세요.");
    } else {
      setEmailWarn("");
    }

  };

  return {
    email,
    emailWarn,
    inputEmail
  };

};