export const numberFormat = (num) => {
  let strNum = typeof num === "string" ? num.replaceAll(",", "") : num;
  return new Intl.NumberFormat().format(strNum);
};

export const phoneFormat = (phone) => {
  return phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

export const bizNumberFormat = (bizNumber) => {
  if (![9, 10].includes(bizNumber.length)) {
    return bizNumber;
  }
  return bizNumber.replace(/(\d{3})(\d{2})(\d{4,5})/, "$1-$2-$3");
};
