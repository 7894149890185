import { useDispatch, useSelector } from "react-redux";
import { guideCloseModal, guideModal, guideOpenModal } from "../../../../store/modal/guide";
import { Overlay } from "../../../../components/modal/styles";
import styled from "styled-components";
import Select from "../../../../components/select/Select";
import { numberFormat } from "../../../../utils/numberFormat";
import moment from "moment";
import { image } from "../../../../themes/theme";
import { useEffect } from "react";

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  position: relative;
  background: #ffffff;
  margin-bottom: 18px;
  & h1 {
    font-size: 18px;
  }
`;

const LeftBtn = styled.div`
  position: absolute;
  left: 16px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const TotalAmount = styled.div`
  width: 100%;
  height: 64px;
  background: transparent;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  & p {
    font-size: 16px;
    color: #444444;
  }
  & div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    & span {
      color: #e96c12;
      font-size: 18px;
    }
    & span:last-child {
      font-size: 16px;
    }
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const DateFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 280px;
  color: #909090;
  gap: 12px;
`;

const DateSeletor = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-weight: 400;
  font-size: 15px;
  color: #444444;
  cursor: pointer;
`;

const SelectFilter = styled.div`
  width: 100%;
  min-width: 280px;

  & select {
    background: transparent !important;
  }
`;

const List = {
  Container: styled.div`
    border-top: 1px solid #e1e1e1;
    width: 100%;
  `,
  ItemBox: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Item: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    height: 70px;
    cursor: pointer;
    background: transparent;
    border-bottom: 1px solid #e1e1e1;
  `,
  Left: styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
  `,
  Date: styled.div`
    font-size: 14px;
    font-weight: 400;
  `,
  TitleBox: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  Title: styled.div`
    font-size: 15px;
    color: #202020;
  `,
  Content: styled.div`
    font-size: 14px;
    color: #444444;
  `,
  Right: styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
  `,
  State: styled.div`
    color: ${(props) =>
      props.status === "전송됨" ? "#E96C12" : props.status === "미확인" ? "#909090" : "#202020"};
    font-size: 14px;
  `,
  AmountBox: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 2px;
    font-size: 15px;
    font-weight: 500;

    & span {
      font-weight: 400;
    }
  `,
  TimeBox: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;
    color: #909090;
  `,
  Time: styled.div`
    font-size: 14px;
    font-weight: 400;
  `
};

const Button = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  background: #e96c12;
  border-radius: 8px;
  cursor: pointer;
`;

const items = [
  {
    documentId: 1,
    amount: 500000,
    company: "선진중기",
    content: "마무리작업",
    endDate: "16:00:00",
    startDate: "18:50:00",
    status: "",
    workDate: "2024-11-02"
  },
  {
    documentId: 2,
    amount: 500000,
    company: "선진중기",
    content: "마무리작업",
    endDate: "16:00:00",
    startDate: "18:50:00",
    status: "",
    workDate: "2024-10-30"
  },
  {
    documentId: 3,
    amount: 500000,
    company: "선진중기",
    content: "마무리작업",
    endDate: "16:00:00",
    startDate: "18:50:00",
    status: "",
    workDate: "2024-11-02"
  },
  {
    documentId: 4,
    amount: 500000,
    company: "선진중기",
    content: "마무리작업",
    endDate: "16:00:00",
    startDate: "18:50:00",
    status: "",
    workDate: "2024-11-03"
  },
  {
    documentId: 5,
    amount: 500000,
    company: "선진중기",
    content: "마무리작업",
    endDate: "16:00:00",
    startDate: "18:50:00",
    status: "",
    workDate: "2024-11-03"
  }
];

const initOptions = [{ label: "선진중기", value: "선진중기" }];

const GuideContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const GuideBox = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.height}%;
  padding: 0;
`;

const GuideTitle = styled.div`
  position: absolute;
  top: -20px;
  left: 14px;
  background: #e96c12;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.875em;
  padding: 4px 16px;
  z-index: 9999;
`;

const GuideFixedTitle = styled.div`
  position: absolute;
  top: -100px;
  right: 12px;
  z-index: 9999;
  width: 134px;
  height: 32px;
  border-radius: 999px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.875em;
`;

const GuideWave = styled.div`
  position: absolute;
  top: -62px;
  right: 84px;
  z-index: 9999;
`;

const GuideContent = styled.div`
  width: 100%;
  height: 100%;
  border: 2px dashed #e96c12;
  border-radius: 12px;
  overflow: hidden;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background: rgba(233, 108, 18, 0.16);
`;

const DocumentGuideModal = () => {
  const dispatch = useDispatch();
  const { open, isLock } = useSelector(guideModal);

  const guideClose = () => {
    dispatch(guideCloseModal());
  };

  useEffect(() => {
    dispatch(guideOpenModal());
  }, []);

  if (!open || isLock) {
    return;
  }

  return (
    <Overlay.Root>
      <div
        style={{
          width: "90%",
          height: "90%",
          background: "#FFFFFF",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          justifyContent: "space-between",
          position: "relative"
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "0",
            top: "-18px",
            zIndex: 9999,
            width: "32px",
            height: "32px",
            borderRadius: "8px",
            background: "rgba(255, 255, 255, 1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          onClick={guideClose}
        >
          <img style={{ width: "20px", height: "18px" }} src={image.close} alt="close" />
        </div>
        <Header>
          <LeftBtn>닫기</LeftBtn>
          <h1>거래명세서 작성</h1>
        </Header>

        <GuideContainer>
          <GuideBox height={20}>
            <GuideTitle>1. 날짜 및 업체 선택</GuideTitle>
            <GuideContent>
              <SearchContainer>
                <DateFilter>
                  <DateSeletor>2023.10.05</DateSeletor>~<DateSeletor>2023.11.01</DateSeletor>
                </DateFilter>
                <SelectFilter>
                  <Select width="100%" value={"선진중기"} option={initOptions} readOnly={true} />
                </SelectFilter>
              </SearchContainer>
            </GuideContent>
          </GuideBox>

          <GuideBox height={12}>
            <GuideTitle>2. 총 금액</GuideTitle>
            <GuideContent>
              <TotalAmount>
                <p>합계</p>
                <div>
                  <span>{numberFormat(77500000)}</span>
                  <span>원</span>
                </div>
              </TotalAmount>
            </GuideContent>
          </GuideBox>

          <GuideBox height={44}>
            <GuideTitle>3. 선택날짜 및 업체 내역</GuideTitle>
            <GuideContent>
              <List.Container>
                {items?.length > 0 && (
                  <List.ItemBox>
                    {items?.map((item, i) => (
                      <List.Item
                        key={i}
                        $isSelect={["전송됨", "미확인"].includes(item.status) ? "true" : "false"}
                      >
                        <List.Left>
                          <List.Date>{moment(item.workDate).format("MM.DD")}</List.Date>
                          <List.TitleBox>
                            <List.Title>{item.company}</List.Title>
                            <List.Content>* {item.content}</List.Content>
                          </List.TitleBox>
                        </List.Left>
                        <List.Right>
                          <List.State status={item.status}>{item.status}</List.State>
                          <List.TitleBox>
                            <List.AmountBox>
                              {numberFormat(item.amount)}
                              <span>원</span>
                            </List.AmountBox>
                            <List.TimeBox>
                              <List.Time>
                                {moment(`${item.workDate} ${item.startDate}`).format("HH:mm")}
                              </List.Time>
                              ~
                              <List.Time>
                                {moment(`${item.workDate} ${item.endDate}`).format("HH:mm")}
                              </List.Time>
                            </List.TimeBox>
                          </List.TitleBox>
                        </List.Right>
                      </List.Item>
                    ))}
                  </List.ItemBox>
                )}
              </List.Container>
            </GuideContent>
          </GuideBox>
          <GuideBox height={10}>
            <GuideFixedTitle>4. 자동으로 작성</GuideFixedTitle>
            <GuideWave>
              <img src={image.documentGuideBorder} alt={""} />
            </GuideWave>
            <Button>거래명세서 생성</Button>
          </GuideBox>
        </GuideContainer>
      </div>
    </Overlay.Root>
  );
};

export default DocumentGuideModal;
