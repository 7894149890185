import styled from "styled-components";
import ROUTES from "../../../../router/routes";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setAlert, simpleAlert} from "../../../../store/alert";
import {createDocuments, createInvoice} from "../../../../api/document";

const Container = styled.div`
  background: #FFFFFF;
  width: 100%;
  max-width: 767px;
  min-width: 360px;
  height: 72px;
  box-shadow: 0 2px 8px #0000001f;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

const Button = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  background: #e96c12;
  border-radius: 8px;
  cursor: pointer;
`;

export default function DocumentCreateButton({ search }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const create = async () => {
        try {
            const params = {
                company: search.company,
                startDate: search.startDate,
                endDate: search.endDate
            };

            const response = await createInvoice(params);
            navigate(`/send/invoice/${response.data.data}`)
        } catch (e) {
            console.log(e);
            dispatch(simpleAlert(e.response.data.message));
        }
    }

  return (
    <Container>
      <Button onClick={create}>거래명세서 생성</Button>
    </Container>
  );
}
