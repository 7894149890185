import { backConfirm, setConfirm } from "../../store/alert";
import ROUTES from "../../router/routes";
import GlobalHeader from "../../layouts/GlobalHeader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BizLink, MyDiv, PolicyButton, PolicyDiv } from "./styles";
import InputWrap from "../../components/wrap/input/InputWrap";
import Button from "../../components/button/Button";
import Equipment from "../../components/Equipment";
import { userInfo } from "../../api/user";
import { useEffect, useState } from "react";
import GlobalFooter from "../../layouts/GlobalFooter";
import { phoneFormat } from "../../utils/numberFormat";

export default function My() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [visible, setVisible] = useState(true);

  const getUserInfo = async () => {
    try {
      const response = await userInfo();
      const data = response.data.data[0];

      setName(data.name);
      setEmail(data.email);
      setPhone(phoneFormat(data.phone));
      setEquipments(data.equipmentList);
      setVisible(data.sns);
    } catch (e) {
      navigate(ROUTES.login);
    }
  };

  const logoutHandler = () => {
    dispatch(
      setConfirm({
        message: "로그아웃 하시겠습니까?",
        onClick: () => {
          localStorage.removeItem("sunjin-user-session");
          navigate(ROUTES.splash);
        }
      })
    );
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      <GlobalHeader
        title={"마이페이지"}
        left={"취소"}
        right={"로그아웃"}
        onLeftClick={() => dispatch(backConfirm(() => navigate(ROUTES.main)))}
        onRightClick={logoutHandler}
      ></GlobalHeader>

      <MyDiv>
        <InputWrap
          label={"이름"}
          input={{
            width: "100%",
            readOnly: true,
            value: name
          }}
        />
        <InputWrap
          $visible={visible}
          label={"이메일"}
          input={{
            width: "100%",
            readOnly: true,
            value: email
          }}
        />
        <Button
          type={"black"}
          width={"100%"}
          height={"48px"}
          radius={"8px"}
          text={"비밀번호 변경"}
          $visible={visible}
          Click={() => navigate(ROUTES.changePw)}
        />
        <InputWrap
          label={"전화번호"}
          input={{
            readOnly: true,
            width: "100%",
            value: phone
          }}
          button={{
            type: "black",
            width: "40%",
            height: "48px",
            text: "전화번호 변경",
            radius: "8px",
            fontSize: "16px",
            Click: () => navigate(ROUTES.changePhone)
          }}
        />
        <BizLink to={"/my/biz"}>사업자정보 수정</BizLink>
        <Equipment list={equipments} updateList={getUserInfo} />
        <PolicyDiv>
          <span>이용약관</span>
          <PolicyButton onClick={() => navigate("/policy/terms")}>약관보기</PolicyButton>
        </PolicyDiv>
      </MyDiv>

      <GlobalFooter />
    </>
  );
}
