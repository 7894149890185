import Label from "../../label/Label";
import Button from "../../button/Button";
import Select from "../../select/Select";
import { InputDiv, InputWrapStyle, WrapDiv } from "../input/styles";

export default function SelectWrap(props) {
  const {
    label,
    select,
    button,
    $margin
  } = props;

  return (
    <>
      <InputWrapStyle $margin={$margin}>
        <Label text={label} />

        <WrapDiv>
          <InputDiv>
            <Select
              {...select}
            />
          </InputDiv>
          {button && <Button
            {...button}
          />}
        </WrapDiv>
      </InputWrapStyle>
    </>
  );

}