import MainHeader from "../../layouts/MainHeader";
import MainContent from "./components/MainContent";
import MainListContent from "./components/MainListContent";
import {useState} from "react";
import DocumentDetails from "../Documents/components/DocumentDetails";
import BottomSheet from "../../components/BottomSheet";
import GlobalFooter from "../../layouts/GlobalFooter";
import Popup from "../../components/Popup";
import MainFixedButton from "../../components/MainFixedButton";

export default function MainPage() {
  const [selector, setSelector] = useState(null);

  const getItem = (item) => {
    setSelector(item);
  };

  return (
    <>
      <MainHeader getItem={getItem} />
      <MainContent />
      <MainListContent getItem={getItem} />
      <GlobalFooter $marginTop={"0"} />
      <MainFixedButton />
      <BottomSheet
        isOpen={!!selector}
        close={() => setSelector(null)}
        children={<DocumentDetails documentId={selector} close={() => setSelector(null)} />}
      />
      <Popup />
    </>
  );
}
