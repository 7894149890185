import GlobalHeader from "../../layouts/GlobalHeader";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import DocumentHistoryList from "./components/DocumenHistorytList";
import {getDocumentList} from "../../api/document";
import dayjs from "dayjs";
import styled from "styled-components";
import SearchFilter from "../Documents/components/SearchFilter";
import TotalAmount from "../Documents/components/TotalAmount";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.$isActive ? "calc(100vh - 130px)" : "calc(100vh - 60px)")};
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const initSearch = {
  company: "전체",
  startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD")
};

const initOptions = [{ label: "전체", value: "전체" }];

export default function DocumentHistoryPage() {
  const navigate = useNavigate();

  // 검색
  const [search, setSearch] = useState(initSearch);

  // 목록 item
  const [items, setItems] = useState([]);

  // 회사 select option
  const [company, setCompany] = useState(initOptions);

  // getDocumentList
  const documentList = async (search) => {
    const formattedSearch = {
      ...search,
      startDate: dayjs(search.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(search.endDate).format("YYYY-MM-DD")
    };

    const response = await getDocumentList(formattedSearch);

    const responseData = response?.data?.data;
    setItems(responseData);
  };

  // getCompanyList
  const companyList = async (initSearch) => {
    const response = await getDocumentList(initSearch);

    const data = response?.data?.data;

    // 중복 제거 한 회사 목록 추출
    const allData = data.map((item) => item.company);
    const unique = [...new Set(allData)];
    const allOptions = [
      ...initOptions,
      ...unique.map((company) => ({ label: company, value: company }))
    ];

    setCompany(allOptions);
  };

  useEffect(() => {
    documentList(search);
    companyList(initSearch);
  }, [search]);

  return (
    <>
      <GlobalHeader title="작업확인서 내역" left="닫기" onLeftClick={() => navigate(-1)} />

      <Content $isActive={search.company !== "전체"}>
        <SearchFilter search={search} setSearch={setSearch} options={company} />
        <TotalAmount
          amount={items
            .map((item) => item.amount)
            .reduce((prev, curr) => prev + curr, 0)}
        />
        <DocumentHistoryList items={items} />
      </Content>
    </>
  );
}
