import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/modal";
import moment from "moment/moment";
import Calendar from "react-calendar";
import "./index.css";

const Container = styled.div`
  width: 328px;
  height: 376px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-radius: 8px;
  overflow: hidden;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  position: relative;
  background: #ffffff;
  font-size: 16px;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const SubmitBtn = styled.button`
  width: 100%;
  height: 48px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e96c12;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
`;

const ClearBtn = styled.button`
  width: 100%;
  height: 48px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  color: #444444;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
`;

const CalendarModal = ({ value, onChange, minDate, maxDate }) => {
  const dispatch = useDispatch();

  const close = () => dispatch(closeModal());

  const save = (value) => {
    onChange(value);
    close();
  };

  return (
    <Container>
      <Calendar
        locale="ko"
        calendarType="US"
        formatDay={(locale, date) => moment(date).format("D")}
        navigationLabel={null}
        next2Label={null}
        prev2Label={null}
        minDetail="month"
        maxDetail="month"
        defaultValue={value ?? new Date()}
        onChange={save}
        // minDate={maxDate}
        // maxDate={maxDate}
      />
    </Container>
  );
};
export default CalendarModal;
