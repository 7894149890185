import styled from "styled-components";

export const InputWrapStyle = styled.div`
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin: ${(prop) => prop.$margin || 0};
  display: ${(props) => (props.$visible ? "none" : "flex")};
`;

export const WrapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const InputDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
