import Sheet from "react-modal-sheet";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const CustomSheet = styled(Sheet)`
  .react-modal-sheet-backdrop {
  }
  & .react-modal-sheet-container {
    max-width: 767px;
    min-width: 360px;
    left: ${(props) => props.left}px !important;
  }
  .react-modal-sheet-header {
  }
  .react-modal-sheet-drag-indicator {
  }
  .react-modal-sheet-content {
  }
`;

export default function BottomSheet({ isOpen, close, children }) {
  const ref = useRef();
  const [leftWidth, setLeftWidth] = useState(0);

  function leftCalculation() {
    const overlaySize = window.innerWidth;
    const rootSize = document.getElementById("root").offsetWidth;

    if (overlaySize < 767) {
      return 0;
    }

    setLeftWidth((overlaySize - rootSize) / 2);
  }

  const disableBackgroundScroll = (e) => {
    if (e.target.closest(".scrollable-content")) {
      e.stopPropagation();
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isOpen) {
      leftCalculation();
      window.addEventListener("resize", leftCalculation);

      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", disableBackgroundScroll, { passive: false });
    }

    return () => {
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", disableBackgroundScroll, { passive: false });

      window.removeEventListener("resize", leftCalculation);
    };
  }, [isOpen]);

  return (
    <CustomSheet
      ref={ref}
      isOpen={isOpen}
      onClose={close}
      snapPoints={[-50, 0.5, 100, 0]}
      initialSnap={1}
      left={leftWidth}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content style={{ paddingBottom: ref.current?.y }}>
          <Sheet.Scroller draggableAt="both">{children}</Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={close} />
    </CustomSheet>
  );
}
