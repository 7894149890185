import styled from "styled-components";
import { useEffect, useState } from "react";
import { getFooter } from "../../api/set";

const Footer = styled.footer`
  margin-top: ${props => props.$marginTop || '32px'};
  border-top: 1px solid #E1E1E1;
  padding: 24px 16px;
  font-size: 14px;
  color: rgba(144, 144, 144, 1);
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FooterDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const FooterHeader = styled.span`
  color: rgba(68, 68, 68, 1);
  margin-bottom: 16px;
  font-weight: 500;
`;

export default function GlobalFooter({$marginTop}) {

  const [name, setName] = useState("");
  const [bizName, setBizName] = useState("");
  const [bizNumber, setBizNumber] = useState("");
  const [privacyManager, setPrivacyManager] = useState("");
  const [address, setAddress] = useState("");
  const [customerCenter, setCustomerCenter] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");

  const getData = async () => {
    try {
      const response = await getFooter();
      const data = response.data.data;

      setName(data.name);
      setBizName(data.bizName);
      setBizNumber(data.bizNumber);
      setPrivacyManager(data.privacyManager);
      setAddress(data.address);
      setCustomerCenter(data.customerCenter);
      setFax(data.fax);
      setEmail(data.email);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Footer $marginTop={$marginTop}>
        <FooterHeader>{bizName}</FooterHeader>
        <FooterDiv>
          <p>대표자: {name}</p>
          <p>개인정보관리 책임자: {privacyManager}</p>
        </FooterDiv>
        <p>사업자 등록번호: {bizNumber}</p>
        <p>사업장주소: {address}</p>
        <p>고객센터: {customerCenter}</p>
        <p>팩스(FAX): {fax}</p>
        <p>이메일 문의: {email}</p>
      </Footer>
    </>
  );

}
