import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  onClick: null,
  isConfirm: false,
  isBack: false
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    simpleAlert: (state, { payload }) => {
      state.open = true;
      state.message = payload;
      state.onClick = null;
    },
    setAlert: (state, { payload }) => {
      state.open = true;
      state.message = payload.message;
      state.onClick = payload.onClick;
    },
    setConfirm: (state, { payload }) => {
      state.open = true;
      state.isConfirm = true;
      state.message = payload.message;
      state.onClick = payload.onClick;
    },
    backConfirm: (state, { payload }) => {
      state.open = true;
      state.isConfirm = true;
      state.isBack = true;
      state.message = "페이지 이탈 시,\n내용이 삭제됩니다. 나가시겠습니까?.";
      state.onClick = payload;
    },
    closeAlert: (state) => {
      return initialState;
    }
  }
});

export const alert = (state) => state.alert;

export const { simpleAlert, setAlert, setConfirm, backConfirm, closeAlert } = alertSlice.actions;

const alertReducer = alertSlice.reducer;

export default alertReducer;
