import styled from "styled-components";

export const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  padding: 16px;
  gap: 24px;
`;

export const FindDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: rgba(144, 144, 144, 1);
  
  & button {
    font-size: 14px;
    color: rgba(144, 144, 144, 1);
  }
`;