import GlobalHeader from "../../layouts/GlobalHeader";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../router/routes";
import { backConfirm, setAlert, simpleAlert } from "../../store/alert";
import { useDispatch } from "react-redux";
import { JoinDiv } from "./styles";
import InputWrap from "../../components/wrap/input/InputWrap";
import { useEffect, useState } from "react";
import SelectWrap from "../../components/wrap/select/SelectWrap";
import Button from "../../components/button/Button";
import { openModal } from "../../store/modal";
import DaumPost from "../../components/modal/DaumPost";
import { signup } from "../../api/user";
import useAuthCode from "../../hook/useAuthCode";
import usePasswordCheck from "../../hook/usePasswordCheck";
import useEmailCheck from "../../hook/useEmailCheck";
import useBizInfo from "../../hook/useBizInfo";

export default function JoinPage() {
  const {
    setIsNew,
    phone,
    setPhone,
    phoneButton,
    phoneReadOnly,
    setCode,
    codeButton,
    codeReadOnly,
    success,
    formatTime,
    sendCode,
    checkCode
  } = useAuthCode();

  const { password, checkPassword, setCheckPassword, passwordWarn, checkWarn, inputPassword } =
    usePasswordCheck();

  const { email, emailWarn, inputEmail } = useEmailCheck();

  const {
    bizNumber,
    setBizNumber,
    bizName,
    setBizName,
    ceo,
    setCeo,
    address,
    setAddress,
    addressDetail,
    setAddressDetail,
    bizStatus,
    setBizStatus,
    bizCategory,
    setBizCategory,
    equipment,
    size,
    setSize,
    bizStatusList,
    bizCategoryList,
    equipmentList,
    equipmentOption,
    unit,
    sizeReadOnly,
    changeEquipment
  } = useBizInfo();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(true);

  const [name, setName] = useState("");

  useEffect(() => {
    setIsNew(true);
  }, []);

  useEffect(() => {
    const warn = !emailWarn && !passwordWarn && !checkWarn;

    const allFieldsFilled =
      name !== "" &&
      email !== "" &&
      password !== "" &&
      checkPassword !== "" &&
      bizNumber !== "" &&
      bizName !== "" &&
      ceo !== "" &&
      address !== "" &&
      addressDetail !== "" &&
      bizStatus !== "" &&
      bizCategory !== "" &&
      equipment !== "" &&
      size !== "" &&
      success;

    setDisabled(!(allFieldsFilled && warn));
  }, [
    name,
    email,
    password,
    checkPassword,
    bizNumber,
    bizName,
    ceo,
    address,
    addressDetail,
    bizStatus,
    bizCategory,
    equipment,
    size,
    success,
    emailWarn,
    passwordWarn,
    checkWarn
  ]);

  const join = async () => {
    const request = {
      name: name,
      email: email,
      password: password,
      phone: phone,
      bizNumber: bizNumber,
      bizName: bizName,
      bizCeo: ceo,
      bizAddress: address,
      bizAddressDetail: addressDetail,
      bizStatus: bizStatus,
      bizCategory: bizCategory,
      equip: equipment,
      size: size,
      unit: unit,
      isSns: false
    };

    try {
      await signup(request);

      dispatch(
        setAlert({
          message: "회원가입이 정상적으로\n완료 되었습니다.",
          onClick: () => navigate(ROUTES.login)
        })
      );
    } catch (e) {
      dispatch(simpleAlert(e.response.data.message));
    }
  };

  return (
    <>
      <GlobalHeader
        title={"회원가입"}
        left={"취소"}
        onLeftClick={() => dispatch(backConfirm(() => navigate(ROUTES.splash)))}
      ></GlobalHeader>

      <JoinDiv>
        <InputWrap
          label={"이름"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "이름을 입력해 주세요.",
            onChange: (e) => setName(e)
          }}
        />
        <InputWrap
          label={"이메일"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "이메일 형식으로 입력해 주세요.",
            $warn: emailWarn,
            mode: "email",
            onChange: (e) => inputEmail(e)
          }}
        />
        <InputWrap
          label={"비밀번호"}
          input={{
            width: "100%",
            type: "password",
            placeholder: "비밀번호를 입력해 주세요.",
            $option: "password",
            $warn: passwordWarn,
            onChange: (e) => inputPassword(e)
          }}
        />
        <InputWrap
          label={"비밀번호 확인"}
          input={{
            width: "100%",
            type: "password",
            placeholder: "비밀번호를 한번 더 입력해 주세요.",
            $option: "password",
            $warn: checkWarn,
            onChange: (e) => setCheckPassword(e)
          }}
        />
        <div>
          <InputWrap
            label={"전화번호 인증"}
            input={{
              type: "phone",
              placeholder: "'-' 제외 번호를 입력해 주세요.",
              readOnly: phoneReadOnly,
              onChange: (e) => setPhone(e),
              width: "100%",
              mode: "numeric",
              maxLength: 11
            }}
            button={{
              type: "orange",
              width: "40%",
              height: "48px",
              text: "인증번호 발송",
              radius: "8px",
              fontSize: "16px",
              disabled: phoneButton,
              Click: sendCode
            }}
          />
          <InputWrap
            input={{
              type: "phone",
              placeholder: "인증번호 입력",
              onChange: (e) => setCode(e),
              readOnly: codeReadOnly,
              $option: formatTime(),
              width: "100%",
              mode: "numeric",
              maxLength: 6
            }}
            button={{
              type: "orange",
              width: "40%",
              height: "48px",
              text: "확인",
              radius: "8px",
              fontSize: "16px",
              disabled: codeButton,
              Click: checkCode
            }}
          />
        </div>
        <InputWrap
          label={"사업자 등록번호"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "'-' 제외 번호를 입력해 주세요.",
            onChange: (e) => setBizNumber(e),
            maxLength: 10
          }}
        />
        <InputWrap
          label={"상호명"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "상호명을 입력해 주세요.",
            onChange: (e) => setBizName(e)
          }}
        />
        <InputWrap
          label={"사업자 대표명"}
          input={{
            width: "100%",
            type: "text",
            placeholder: "사업자 대표명을 입력해 주세요.",
            onChange: (e) => setCeo(e)
          }}
        />
        <div>
          <InputWrap
            label={"사업자 소재지"}
            input={{
              type: "text",
              value: address,
              placeholder: "도로명주소",
              width: "100%",
              readOnly: true
            }}
            button={{
              type: "orange",
              width: "40%",
              height: "48px",
              text: "우편번호",
              radius: "8px",
              fontSize: "16px",
              Click: () => dispatch(openModal(<DaumPost onChange={setAddress} />))
            }}
          />
          <InputWrap
            input={{
              type: "text",
              placeholder: "상세주소를 입력해 주세요.",
              onChange: (e) => setAddressDetail(e)
            }}
          />
        </div>
        <SelectWrap
          label={"업태"}
          select={{
            width: "100%",
            option: bizStatusList
              ? bizStatusList.map((item) => ({
                  label: item.name,
                  value: item.name
                }))
              : [],
            onChange: (e) => setBizStatus(e)
          }}
        />
        <SelectWrap
          label={"업종"}
          select={{
            width: "100%",
            option: bizCategoryList
              ? bizCategoryList.map((item) => ({
                  label: item.name,
                  value: item.name
                }))
              : [],
            onChange: (e) => setBizCategory(e)
          }}
        />
        <div>
          <SelectWrap
            label={"장비 종류 및 규격"}
            select={{
              width: "100%",
              option: equipmentList
                ? equipmentList.map((item) => ({
                    label: item.name,
                    value: item.name
                  }))
                : [],
              onChange: (e) => changeEquipment(e)
            }}
          />
          <InputWrap
            input={{
              type: "float",
              placeholder: "숫자만 입력해 주세요",
              $option: equipmentOption,
              readOnly: sizeReadOnly,
              mode: "numeric",
              onChange: (e) => setSize(e)
            }}
          />
        </div>

        <Button
          type={"orange"}
          width={"100%"}
          height={"56px"}
          radius={"999px"}
          text={"가입 완료"}
          fontSize={"18px"}
          $margin={"32px 0 0 0"}
          disabled={disabled}
          Click={join}
        />
      </JoinDiv>
    </>
  );
}
