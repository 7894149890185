import styled from "styled-components";
import { Link } from "react-router-dom";

export const MyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  gap: 24px;
  padding: 16px;
`;

export const BizLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: rgba(144, 144, 144, 1);
  text-decoration: underline;
`;

export const PolicyDiv = styled.div`
  width: 100%;
  height: 56px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  background: rgba(252, 252, 252, 1);
`;

export const PolicyButton = styled.button`
  color: #e96c12;
  font-size: 16px;
  text-decoration: underline;
`;

export const ChangeButtonDiv = styled.div`
  position: fixed;
  bottom: 0;
  min-width: 360px;
  max-width: 767px;
  width: 100%;
  padding: 16px;

  & div {
    margin: 0;
    background: #ffffff;
  }
`;
