import styled from "styled-components";
import { colors } from "../../themes/theme";

export const Overlay = {
  Root: styled.div`
    width: 100%;
    height: 100%;
    max-width: 767px;
    min-width: 360px;
    overflow: hidden;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${colors.dialogBg};
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  `
};
