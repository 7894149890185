import { ContentDiv, IdDiv, IdSpan, SuccessDiv, SuccessText } from "../styles";
import GlobalHeader from "../../../layouts/GlobalHeader";
import ButtonWrap from "../../../components/wrap/button/ButtonWrap";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "../../../router/routes";
import { findId } from "../../../api/user";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function SuccessId() {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [createdAt, setCreatedAt] = useState("");

  useEffect(() => {
    setEmail(location.state?.email);
    setCreatedAt(location.state?.createdAt);
  }, []);

  return (
    <>
      <GlobalHeader title={"아이디 찾기"} />

      <SuccessDiv>
        <SuccessText>
          <span>회원가입 시 입력하신 정보와</span>
          <span>일치한 아이디 정보입니다.</span>
        </SuccessText>

        <IdDiv>
          <ContentDiv>
            <span>아이디</span>
            <IdSpan>{email}</IdSpan>
          </ContentDiv>

          <ContentDiv>
            <span>가입일</span>
            <span>{createdAt}</span>
          </ContentDiv>
        </IdDiv>

        <ButtonWrap
          button1={{
            type: "line",
            disable: true,
            width: "100%",
            height: "56px",
            text: "비밀번호 찾기",
            radius: "999px",
            fontSize: "18px",
            Click: () => navigate(ROUTES.findPw, { state: { email: email } })
          }}
          button2={{
            type: "orange",
            disable: true,
            width: "100%",
            height: "56px",
            text: "로그인",
            radius: "999px",
            fontSize: "18px",
            Click: () => navigate(ROUTES.login)
          }}
        />
      </SuccessDiv>
    </>
  );
}
