import { backConfirm, setAlert } from "../../../store/alert";
import ROUTES from "../../../router/routes";
import GlobalHeader from "../../../layouts/GlobalHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import InputWrap from "../../../components/wrap/input/InputWrap";
import React, { useEffect, useState } from "react";
import { FindDiv } from "../styles";
import Button from "../../../components/button/Button";
import { changePw } from "../../../api/user";
import usePasswordCheck from "../../../hook/usePasswordCheck";

export default function SuccessPassword() {

  const {
    password,
    checkPassword,
    setCheckPassword,
    passwordWarn,
    checkWarn,
    inputPassword
  } = usePasswordCheck();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {

    const warn = !passwordWarn && !checkWarn;
    const input = password !== "" && checkPassword !== "";

    setDisabled(!(warn && input));

  }, [password, checkPassword, passwordWarn, checkWarn]);

  const result = async () => {

    const email = location.state?.email;

    if (!email) {
      dispatch(setAlert({
        message: "다시 시도해 주세요.",
        onClick: () => navigate(ROUTES.findPw)
      }));
    }

    const request = {
      email: email,
      password: password
    };

    try {
      await changePw(request);

      dispatch(setAlert({
        message: "비밀번호가 변경되었습니다.\n로그인 페이지로 이동합니다.",
        onClick: () => navigate(ROUTES.login)
      }));
    } catch (e) {
      console.log(e);
    }

  };

  return (
    <>
      <GlobalHeader title={"비밀번호 찾기"} left={"취소"}
                    onLeftClick={() => dispatch(backConfirm(() => navigate(ROUTES.login)))} />
      <FindDiv>
        <InputWrap
          label={"새 비밀번호"}
          input={{
            width: "100%",
            type: "password",
            placeholder: "새 비밀번호를 입력해 주세요.",
            $option: "password",
            $warn: passwordWarn,
            onChange: (e) => inputPassword(e)
          }}
          $margin={"0 0 32px 0"}
        />

        <InputWrap
          label={"비밀번호 확인"}
          input={{
            width: "100%",
            type: "password",
            placeholder: "새 비밀번호를 입력해 주세요.",
            $option: "password",
            $warn: checkWarn,
            onChange: (e) => setCheckPassword(e)
          }}
        />

        <Button
          type={"orange"}
          disabled={disabled}
          width={"100%"}
          height={"56px"}
          radius={"999px"}
          text={"비밀번호 변경"}
          fontSize={"18px"}
          $margin={"32px 0 0 0"}
          Click={result}
        />
      </FindDiv>
    </>
  );

}