import styled from "styled-components";

export const InvoiceDiv = styled.div`
  width: 794px;
  height: 1123px;
  border: 2px solid #000000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: #FFFFFF;
`;

export const InvoiceTitle = styled.h1`
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  margin-top: 50px;
  letter-spacing: 7px;
`;

export const InvoiceSubtitle = styled.h3`
  font-size: 18px;
  color: rgba(102, 102, 102, 1);
  text-align: center;
  margin-bottom: 24px;
`;

export const InvoiceHeader = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
`;

export const InvoiceHeaderTo = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & span {
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
  }
`;

export const InvoiceHeaderFrom = styled.div`
  width: 70%;
  display: flex;
  background: rgba(238, 238, 238, 1);

  & > div {
    padding: 10px;
  }

  & .label {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    background: #D7D7D7;
    writing-mode: vertical-rl;
    letter-spacing: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ceo {
    font-size: 16px;
    font-weight: 600;
  }

  & .info {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 18px;
    gap: 12px;

    & .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & span {
        width: 50%;
      }
    }
  }
`;

export const InvoiceTotal = styled.div`
  width: 90%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    padding: 10px 0;
    letter-spacing: ${props => props.letterSpacing || 'normal'};
  }

  & .label {
    width: 40%;
    border-right: 1px solid #000000;
  }

  & .price {
    width: 60%;
  }
`;

export const InvoiceTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  & > div:not(.header):last-child {
    border-bottom: 1px solid #000000;
  }
`;

export const InvoiceTableH = styled.div`
  display: flex;
  margin-bottom: 1px;

  & span:last-child {
    border-right: 0;
  }
`;

export const InvoiceTableHD = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width || "13%"};
  height: 40px;
  border: 1px solid #000000;
  border-left: 0;
  font-weight: 500;
  letter-spacing: ${props => props.letterSpacing || '7px'};
`;

export const InvoiceTableR = styled.div`
  display: flex;

  & span:last-child {
    border-right: 0;
  }
`;

export const InvoiceTableRD = styled.span`
  display: flex;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: center;
  width: ${props => props.width || "13%"};
  padding: 4px 8px;

  border: 1px solid #000000;
  border-left: 0;
  border-bottom: 0;
`;

export const InvoiceTableF = styled.div`
  display: flex;

  & span:last-child {
    border-right: 0;
  }
  
  &:last-child span {
    border-top: 0;
    border-bottom: 0;
  }
`;

export const InvoiceTableFD = styled.span`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  width: ${props => props.width || '12%'};
  flex: ${props => props.$flex ? "0 0 50%" : "1"};
  padding: 4px 8px;

  border: 2px solid #000000;
  border-right-width: 1px;
  border-left-width: 0;
  font-weight: ${props => props.$weight || "400"};
`;

export const InvoiceTableFDSecond = styled.span`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  width: ${props => props.width || '13%'};
  padding: 4px 8px;

  border: 2px solid #000000;
  border-right-width: 1px;
  border-left-width: 0;
  font-weight: ${props => props.$weight || "400"};
`;