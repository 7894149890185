import MainCalendar from "../MainCalendar";
import { image } from "../../../../themes/theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../../router/routes";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  background: #f9f9f9;
  padding: 24px 0;
`;

const CalendarContainer = styled.div`
  background: #ffffff;
  width: 328px;
  height: 400px;
  overflow: hidden;
  box-shadow: 0 0 5px #e0e0e0;
  border-radius: 8px;
`;

const Content = styled.div`
  width: 328px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Btn = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  gap: 8px;
  cursor: pointer;
`;

const Btn1 = styled(Btn)`
  background: #202020;
  color: #ffffff;
`;

const Btn2 = styled(Btn)`
  background: #ffffff;
  border: 1px solid #6b6b6b;
`;

export default function MainContent() {

  const navigate = useNavigate();

  return (
    <Container>
      <CalendarContainer>
        <MainCalendar />
      </CalendarContainer>
      <Content>
        <Btn1 onClick={() => navigate(ROUTES.documents)}>
          <img src={image.folder} alt="documents" />
          <p>거래명세서 작성</p>
        </Btn1>
        <Btn2 onClick={() => navigate(ROUTES.my)}>
          <img src={image.user} alt="my" />
          <p>내정보</p>
        </Btn2>
      </Content>
    </Container>
  );
}
