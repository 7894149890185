import styled from "styled-components";
import GlobalHeader from "../../layouts/GlobalHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getPolicy } from "../../api/set";

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 767px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
`;


const Content = styled.div`
  height: 100%;
  padding: 24px 32px;
`;


export default function Policy() {

  const navigate = useNavigate();
  const { type } = useParams();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const get = async () => {
    try {
      const response = await getPolicy(type);
      const data = response.data.data;

      setTitle(data.title);
      setContent(data.content);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <Container>
      <GlobalHeader title={title} left="닫기" onLeftClick={() => navigate(-1)} />

      <Content dangerouslySetInnerHTML={{ __html: content }} />
    </Container>
  );
};