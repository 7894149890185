import styled from "styled-components";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/modal";
import SignatureCanvas from "react-signature-canvas";
import React, { useRef } from "react";

const Buffer = require("buffer/").Buffer;

const Container = styled.div`
  width: 328px;
  height: 376px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-radius: 8px;
  overflow: hidden;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  position: relative;
  background: #ffffff;
  font-size: 16px;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const SubmitBtn = styled.button`
  width: 100%;
  height: 48px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e96c12;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
`;

const ClearBtn = styled.button`
  width: 100%;
  height: 48px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  color: #444444;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
`;

const SignatureModal = ({ onChange }) => {
  const ref = useRef();

  const dispatch = useDispatch();

  const close = () => dispatch(closeModal());

  const clear = () => ref.current.clear();

  const convertDataUrlToFile = () => {
    const dataURL = ref.current.toDataURL("image/png");

    const decodedURL = dataURL.replace(/^data:image\/\w+;base64,/, "");

    const buf = Buffer.from(decodedURL, "base64");

    const blob = new Blob([buf], { type: "image/png" });

    return new File([blob], "sign_image.png", { type: "image/png" });
  };

  const save = () => {
    const file = convertDataUrlToFile();
    onChange(file);

    clear();
    close();
  };

  return (
    <Container>
      <Header>서명을 해주세요</Header>
      <SignatureCanvas
        ref={ref}
        canvasProps={{ className: "sigCanvas", width: 328, height: 280 }}
        backgroundColor="rgb(255, 255, 255)"
      />
      <ButtonContainer>
        <ClearBtn onClick={clear}>지우기</ClearBtn>
        <SubmitBtn onClick={save}>저장</SubmitBtn>
      </ButtonContainer>
    </Container>
  );
};
export default SignatureModal;
