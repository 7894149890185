const fontSizes = {
  xl: "1.25rem",
  l: "1.125rem",
  base: "1rem",
  s: "0.875rem",
  xs: "0.8125rem",
  xxs: "0.75rem"
};

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700
};

export const colors = {
  black: "#000000",
  white: "#FFFFFF",
  blue: "#1E88E5",
  red: "#E53935",
  gray1: "#F5F5F5",
  // service color
  darkGray: "#282832",
  darkGrayHover: "#556",
  pointColor: "#32B6AE",
  pointColorHover: "#299A93",
  error: "#B63242",
  errorHover: "#8A1D2A",
  grayMint: "#EBEBEB",
  grayMintHover: "#D9D9D9",
  transparent: "transparent",
  disabled: "#EBEBEB",
  fontDisabled: "rgba(40,40,50,.2)",
  btnBlockHover: "#555566",
  btnMintHover: "#299A93",
  btnRedHover: "#8A1D2A",
  btnGrayHover: "#D9D9D9",
  tdOdd: "#f9f9fa",
  thBorder: "#ebedef",
  tdColor: "#3c44e9",
  resultBg: "rgba(40, 40, 50, 0.80)",
  listBorder: "rgba(255,255,255,.5)",
  listBorder01: "rgba(40, 40, 50, .5)",
  loginInput: "rgba(255,255,255,.1)",
  inputBorder: "rgba(134, 134, 155, .2)",
  lnbBg: "#373744",
  tabColor: "#86869B",
  dialogBg: "rgba(40, 40, 50, 0.90)",
  black50: "rgba(0,0,0,.5)",
  black0: "rgba(0,0,0,0)"
};

const URL = process.env.REACT_APP_S3;

export const image = {
  kakaoIcon: "/image/login/kakao.svg",
  googleIcon: "/image/login/google.svg",
  naverIcon: "/image/login/naver.svg",
  appleIcon: "/image/login/apple.svg",
  logo: "/image/icon/logo.svg",
  alarm: "/image/icon/alarm.svg",
  folder: "/image/icon/folder.svg",
  user: "/image/icon/user.svg",
  write: "/image/icon/write.svg",
  close: "/image/icon/close.svg",
  redClose: "/image/icon/close-red.svg",
  trash: "/image/icon/trash.svg",
  plus: "/image/icon/plus.svg",
  deleteBasket: "/image/icon/delete-basket.svg",
  checkbox: "/image/icon/checkbox.svg",
  noneCheckbox: "/image/icon/checkbox2.svg",

  document: URL + "/document/",
  documentGuideBorder: "/image/guide/guide-border.svg"
};

const devices = {
  desktop: "1440px",
  laptop: "1024px",
  tablet: "902px",
  mobile: "425px"
};

const media = {
  desktop: `max-width:${devices.desktop}`,
  laptop: `max-width:${devices.laptop}`,
  tablet: `max-width:${devices.tablet}`,
  mobile: `max-width:${devices.mobile}`
};

const GlobalTheme = {
  fontSizes,
  fontWeights,
  colors,
  media
};

export default GlobalTheme;
