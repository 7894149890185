import { client } from "./index";

const URLS = {
  bizStatus: "/admin/set/biz-status/list",
  bizCategory: "/admin/set/biz-category/list",
  equipment: "/admin/set/equipment/list",
  code: "/auth/code",
  signup: "/auth/signup",
  login: "/auth/login",
  findId: "/auth/find/email",
  changePw: "/auth/update/password",
  userInfo: "/member/userinfo",
  addEquipment: "/member/equipment/insert",
  delEquipment: "/member/equipment/delete/",
  changeMyPw: "/member/userinfo/password/update",
  changeMyPhone: "/member/userinfo/phone/update",
  info: "/member/info",
  updateBiz: "/member/biz/update",
  expireMember: "/member/expire/"
};

export const getBizStatus = async () => {
  return await client.get(URLS.bizStatus);
};

export const getBizCategory = async () => {
  return await client.get(URLS.bizCategory);
};

export const getEquipment = async () => {
  return await client.get(URLS.equipment);
};

export const getCode = async (request) => {
  return await client.post(URLS.code, request);
};

export const signup = async (request) => {
  return await client.post(URLS.signup, request);
};

export const findId = async (request) => {
  return await client.post(URLS.findId, request);
};

export const changePw = async (request) => {
  return await client.post(URLS.changePw, request);
};

export const signIn = async (request) => {
  return await client.post(URLS.login, request);
};

export const userInfo = async () => {
  return await client.get(URLS.userInfo);
};

export const addEquipment = async (request) => {
  return await client.post(URLS.addEquipment, request);
};

export const delEquipment = async (equipmentId) => {
  return await client.delete(URLS.delEquipment + equipmentId);
};

export const changeMyPw = async (request) => {
  return await client.post(URLS.changeMyPw, request);
};

export const changeMyPhone = async (request) => {
  return await client.post(URLS.changeMyPhone, request);
};

export const getInfo = async () => {
  return await client.get(URLS.info);
};

export const updateBiz = async (request) => {
  return await client.post(URLS.updateBiz, request);
};

export const expiredMember = async (memberId) => {
  return await client.post(URLS.expireMember + memberId)
}