import { jwtDecode } from "jwt-decode";

/**
 * 토큰 키
 * @constant
 */
const SESSIONKEY = "sunjin-user-session";

/**
 * 스토리지에 토큰 저장
 * @param loginData
 */
export function saveLoginSession(loginData) {
  localStorage.setItem(SESSIONKEY, JSON.stringify(loginData));
}

/**
 * 스토리지에서 토큰 삭제
 */
export function removeLoginSession() {
  sessionStorage.removeItem(SESSIONKEY);
}

/**
 * 스토리지 토큰 반환
 * @returns { LoginData | null }
 */
export function getLoginSession() {
  const loginSession = localStorage.getItem(SESSIONKEY);
  const loginData = loginSession ? JSON.parse(loginSession) : null;

  if (!loginData) {
    return null;
  }

  try {
    const decoded = jwtDecode(loginData.loginData);
    const current = Math.floor(Date.now() / 1000);
    if (!decoded.exp || decoded.exp < current) {
      localStorage.removeItem("sunjin-user-session");
    }
  } catch (error) {
    console.log(error);
  }

  return loginData;
}
