import axios from "axios";
import { getLoginSession, removeLoginSession } from "../utils/storage";

const API_PATH = process.env.REACT_APP_API_URL;

export const client = axios.create({
  baseURL: API_PATH,
  timeout: 100000,
  headers: {
    "x-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache"
  },
  responseType: "json"
});

const executingRequests = {};

client.interceptors.request.use(
  function (config) {
    const tokenInfo = getLoginSession();

    if (tokenInfo) {
      config.headers.Authorization = `Bearer ${tokenInfo.loginData}`;
    }

    return config;
  },
  function (request) {
    const currentRequest = request;

    if (executingRequests[currentRequest.url]) {
      const source = executingRequests[currentRequest.url];
      delete executingRequests[currentRequest.url];
      source.cancel();
    }

    const source = axios.CancelToken.source();
    currentRequest.CancelToken = source.token;
    executingRequests[currentRequest.url] = source;

    return currentRequest;
  }
);

client.interceptors.response.use(
  function (response) {
    if (executingRequests[response.request.reponseURL]) {
      delete executingRequests[response.request.reponseURL];
    }

    return response;
  },
  function (error) {
    const { config } = error;
    const originalRequest = config;

    if (axios.isCancel(error)) {
      console.error("cancel err: ", error);
    }

    if (executingRequests[originalRequest.url]) {
      delete executingRequests[originalRequest.url];
    }

    if (error.response) {
      console.error("err response: ", error.response);

      if (error.response.data.code === "A01") {
        localStorage.removeItem("sunjin-user-session");
      }
    } else {
      console.error("err message: ", error.message);
    }

    return Promise.reject(error);
  }
);

client.defaults.withCredentials = true;
