import { createSlice } from "@reduxjs/toolkit";
import { getLoginSession, removeLoginSession, saveLoginSession } from "../../utils/storage";

const initialUserState = {
  isLoggedIn: getLoginSession() !== null
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    login: (state, action) => {
      saveLoginSession(action.payload);
      state.isLoggedIn = true;
    },
    logout: (state) => {
      removeLoginSession();
      state.isLoggedIn = false;
    }
  }
});

export const user = (state) => state.user;

export const { login, logout } = userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
