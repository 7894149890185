import styled from "styled-components";

export const EquipmentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  gap: 8px;
`;

export const EquipmentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 8px;
`;

export const EquipmentDataDiv = styled.div`
  width: 100%;
  height: 48px;
  padding: 0 50px 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;

  & img {
    width: 20px;
    height: 20px;
  }
`;

export const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 16px;
  background: #E1E1E1;
`;

export const EquipmentAddDiv = styled.div`
  width: 100%;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #F9F9F9;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const EquipmentButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & button {
    width: 50%;
    height: 48px;
    font-size: 16px;
  }
`;

export const EquipmentCancelButton = styled.button`
  color: rgba(68, 68, 68, 1);
  background: rgba(233, 233, 233, 1);
  border-bottom-left-radius: 6px;
`;

export const EquipmentAddButton = styled.button`
  color: #FFFFFF;
  background: rgba(233, 108, 18, 1);
  border-bottom-right-radius: 6px;
`;