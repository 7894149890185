import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  children: <></>
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      document.body.style.overflow = "hidden";
      state.open = true;
      state.children = payload;
    },
    closeModal: (state) => {
      document.body.style.overflow = "auto";
      return initialState;
    }
  }
});

export const modal = (state) => state.modal;

export const { openModal, closeModal } = modalSlice.actions;

const modalReducer = modalSlice.reducer;

export default modalReducer;
