import styled from "styled-components";

export const StyledPopup = {
  Portal: styled.div`
    display: block;
    visibility: ${(props) => (props.$show ? "visible" : "hidden")};
  `,
  Fade: styled.div`
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    inset: 0;
    z-index: 98;
  `,
  Root: styled.div`
    width: 100%;
    max-width: 767px;
    min-width: 360px;
    height: 60%;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    z-index: 99;
    border-radius: 30px 30px 0 0;
    padding: 24px 24px 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    transform: translateY(${(props) => (props.$show ? "0" : "100%")});
    transition: transform 0.5s ease-in-out;
  `,
  Header: styled.div`
    position: relative;
  `,
  Title: styled.p`
    font-size: 20px;
    font-weight: 500;
  `,
  Close: styled.button`
    position: absolute;
    top: 0;
    right: 0;
  `,
  Content: styled.div`
    width: 100%;
    height: 80%;

    & img {
      width: 100%;
      height: 100%;
    }
  `,
  Footer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Never: styled.button`
    font-size: 16px;
    color: #909090;
    text-decoration-line: underline;
  `
};
