import { DeleteButton, EquipmentDataDiv } from "./styles";
import { image } from "../../themes/theme";
import { useDispatch } from "react-redux";
import { setConfirm, simpleAlert } from "../../store/alert";
import { delEquipment } from "../../api/user";

export default function EquipmentData(props) {

  const {
    text,
    value,
    isSingle,
    updateList
  } = props;

  const dispatch = useDispatch();

  const confirm = () => {
    dispatch(setConfirm({
      message: text + "를\n삭제하시겠습니까?",
      onClick: () => deleteEquipment()
    }));
  };

  const deleteEquipment = async () => {
    if (isSingle) {
      dispatch(simpleAlert("장비는 1개 이상 등록해야 합니다."));
    } else {
      try {
        await delEquipment(value);
        updateList();
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <EquipmentDataDiv>
        <span>{text}</span>
        <DeleteButton onClick={confirm}>
          <img src={image.trash} alt="delete" />
        </DeleteButton>
      </EquipmentDataDiv>
    </>
  );

};