import { client } from "./index";

const URLS = {
  getPolicy: "/admin/set/policy/",
  getFooter: "/admin/set/footer",
  getPopup: "/admin/popup/show"
};

export const getPolicy = async (type) => {
  return await client.get(URLS.getPolicy + type);
};

export const getFooter = async () => {
  return await client.get(URLS.getFooter);
};

export const getPopup = async () => {
  return await client.get(URLS.getPopup);
};
