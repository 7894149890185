import GlobalHeader from "../../../layouts/GlobalHeader";
import { backConfirm, setAlert, simpleAlert } from "../../../store/alert";
import ROUTES from "../../../router/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangeButtonDiv, MyDiv } from "../styles";
import InputWrap from "../../../components/wrap/input/InputWrap";
import usePasswordCheck from "../../../hook/usePasswordCheck";
import ButtonWrap from "../../../components/wrap/button/ButtonWrap";
import { useState } from "react";
import { changeMyPw } from "../../../api/user";

export default function ChangePassword() {

  const {
    password,
    checkPassword,
    setCheckPassword,
    passwordWarn,
    checkWarn,
    inputPassword
  } = usePasswordCheck();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [now, setNow] = useState("");
  const [nowWarn, setNowWarn] = useState("");

  const exit = () => {
    dispatch(backConfirm(() => navigate(ROUTES.my)));
  };

  const change = async () => {

    const warn = !passwordWarn && !checkWarn;
    const input = password !== "" && checkPassword !== "";

    if (now === "" || !!nowWarn) {
      dispatch(simpleAlert("기존 비밀번호를 확인해 주세요."));
    } else {
      if (!(warn && input)) {
        dispatch(simpleAlert("비밀번호를 확인해 주세요."));
      } else {
        const request = {
          password: now,
          newPassword: password,
          newPasswordCheck: checkPassword
        };

        try {
          await changeMyPw(request);

          dispatch(setAlert({
            message: "비밀번호가 변경되었습니다.",
            onClick: () => navigate(ROUTES.my)
          }));
        } catch (e) {
          dispatch(simpleAlert(e.response.data.message));
        }
      }
    }
  };

  return (
    <>
      <GlobalHeader title={"비밀번호 변경"} left={"닫기"}
                    onLeftClick={() => exit()}></GlobalHeader>

      <MyDiv>
        <InputWrap
          label={"기존 비밀번호"}
          input={{
            width: "100%",
            type: "password",
            placeholder: "기존 비밀번호를 입력해 주세요.",
            $option: "password",
            $warn: nowWarn,
            onChange: (e) => setNow(e)
          }}
        />
        <InputWrap
          label={"새 비밀번호"}
          input={{
            width: "100%",
            type: "password",
            placeholder: "영문, 숫자 8-16자리로 입력해 주세요.",
            $option: "password",
            $warn: passwordWarn,
            onChange: (e) => inputPassword(e)
          }}
        />
        <InputWrap
          label={"새 비밀번호 확인"}
          input={{
            width: "100%",
            type: "password",
            placeholder: "비밀번호를 한번 더 입력해 주세요.",
            $option: "password",
            $warn: checkWarn,
            onChange: (e) => setCheckPassword(e)
          }}
        />
      </MyDiv>

      <ChangeButtonDiv>
        <ButtonWrap
          button1={{
            type: "black",
            disable: true,
            width: "100%",
            height: "56px",
            text: "취소",
            radius: "8px",
            fontSize: "18px",
            color: "rgba(68, 68, 68, 1)",
            $back: "#F9F9F9",
            $border: "#F9F9F9",
            Click: () => exit()
          }}
          button2={{
            type: "orange",
            disable: true,
            width: "100%",
            height: "56px",
            text: "변경",
            radius: "8px",
            fontSize: "18px",
            Click: () => change()
          }}
        />
      </ChangeButtonDiv>
    </>
  );

};