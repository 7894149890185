import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user";
import alertReducer from "./alert";
import modalReducer from "./modal";
import guideModalReducer from "./modal/guide";

const reducers = combineReducers({
  user: userReducer,
  alert: alertReducer,
  modal: modalReducer,
  guide: guideModalReducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["admin", "guide"], // persist (slice key 값 할당)
  blacklist: ["alert", "modal"] // not persist
};

const reducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }) //  상태값 중 모든 타입의 값이 할당되어도 에러가 발생하지 않도록
  // 초기 상태값 설정
  //preloadedState:{}
});

export const persistor = persistStore(store);
