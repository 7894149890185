import styled, { css } from "styled-components";

export const BlackButton = css`
  color: rgba(68, 68, 68, 1);
  background: #ffffff;
  border: 1px solid rgba(68, 68, 68, 1);
`;

export const LineButton = css`
  color: rgba(233, 108, 18, 1);
  border: 1px solid rgba(233, 108, 18, 1);
  background: #ffffff;
`;

export const OrangeButton = css`
  color: #ffffff;
  background: rgba(233, 108, 18, 1);
  border: 1px solid rgba(233, 108, 18, 1);
`;

export const DisabledButton = css`
  cursor: not-allowed;
  color: #ffffff;
  background: rgba(215, 215, 215, 1);
  border: 1px solid rgba(215, 215, 215, 1);
`;

export const StyledButton = styled.button`
  display: ${(props) => (props.$visible ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.fontSize || "16px"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  padding: ${(props) => props.$padding || "auto"};
  border-radius: ${(props) => props.radius || "0"};
  margin: ${(props) => props.$margin || "0"};
  ${(props) => props.type === "black" && BlackButton};
  ${(props) => props.type === "orange" && OrangeButton};
  ${(props) => props.type === "line" && LineButton};
  ${(props) => props.disabled && DisabledButton};
  ${(props) => props.$back && `background: ${props.$back};`};
  ${(props) => props.color && `color: ${props.color};`};
  ${(props) => props.$border && `border: ${props.$border};`};
`;
