import { StyledButton } from "./styles";

export default function Button(props) {
  const {
    type,
    text,
    Click,
    disabled,
    width,
    height,
    $padding,
    radius,
    $back,
    color,
    $border,
    fontSize,
    $margin,
    $visible
  } = props;

  const click = () => {
    if (Click) Click();
  };

  return (
    <StyledButton
      type={type}
      disabled={disabled}
      onClick={click}
      width={width}
      height={height}
      $padding={$padding}
      radius={radius}
      $back={$back}
      color={color}
      $border={$border}
      fontSize={fontSize}
      $margin={$margin}
      $visible={$visible}
    >
      {text}
    </StyledButton>
  );
}
