import { LabelStyle } from "./styles";

export default function Label(props) {

  const {
    text
  } = props;

  return (
    <LabelStyle>{text}</LabelStyle>
  );

}