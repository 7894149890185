import styled from "styled-components";
import DaumPostCode from "react-daum-postcode";
import { useDispatch } from "react-redux";
import GlobalHeader from "../../../layouts/GlobalHeader";
import { closeModal } from "../../../store/modal";

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 767px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const DaumPost = ({ onChange }) => {

  const dispatch = useDispatch();

  const close = () => dispatch(closeModal());

  const handleComplete = (data) => {
    let address = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += (extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName);
      }
      address += (extraAddress !== "" ? ` (${extraAddress})` : "");
    }

    onChange(address);
    close();
  };

  return (
    <Container>
      <GlobalHeader title={"주소검색"} right="닫기" onRightClick={close} />
      <DaumPostCode
        className="post-code"
        onComplete={handleComplete}
        autoClose={false}
        style={{ width: "100%", height: "100%" }}
      />
    </Container>
  );
};
export default DaumPost;
