import styled, { css } from "styled-components";

export const InputStyle = css`
  height: 48px;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  border: 1px solid
    ${(props) => (props.$hasValue ? "rgba(225, 225, 225, 1)" : "rgba(32, 32, 32, 1)")};

  &:focus {
    outline: none;
    border-color: ${(props) => (props.$warn ? "rgba(255, 11, 11, 1)" : "rgba(32, 32, 32, 1)")};
  }
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
`;

// 읽기 전용 스타일
export const ReadOnlyStyle = css`
  background: rgba(249, 249, 249, 1);
  color: rgba(144, 144, 144, 1);
  border: 1px solid rgba(225, 225, 225, 1);
`;

// 경고 스타일
export const WarnStyle = css`
  color: rgba(255, 11, 11, 1);
  border-color: rgba(255, 11, 11, 1);
`;

export const WarnMessage = styled.div`
  margin: 8px 0;
  font-size: 14px;
  color: rgba(255, 11, 11, 1);
`;

export const StyledInput = styled.input`
  ${InputStyle}
  ${(props) => props.readOnly && ReadOnlyStyle}
  ${(props) => props.$warn && WarnStyle}
  width: ${(props) => props.width || "auto"};
  padding-right: ${(props) => props.$padding || "12px"};
  padding-left: ${(props) => (!!props.$pre ? "50px" : "12px")};
`;

export const OptionButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const OptionDiv = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const PreDiv = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  color: rgba(144, 144, 144, 1);
`;
