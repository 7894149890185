import styled from "styled-components";
import { image } from "../../themes/theme";
import { useEffect, useState } from "react";
import {deleteNotification, getNotificationList, getNotificationListCount} from "../../api/notification";
import {useDispatch} from "react-redux";
import {setAlert, setConfirm, simpleAlert} from "../../store/alert";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../router/routes";

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 0 16px;
  position: relative;
  background: #ffffff;
`;

const Logo = styled.div`
  position: absolute;
  left: 16px;
  height: 32px;
`;

const RightBtn = styled.div`
  position: absolute;
  right: 16px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const RelativeBox = styled.div`
  position: relative;
`;

const AlarmCount = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 99;
  background: #ff5e5e;
  border-radius: 999px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
`;

const AlarmModal = styled.div`
  position: absolute;
  bottom: -200px;
  right: 10px;
  width: 312px;
  height: 200px;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 0 5px #e0e0e0;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 0 8px 12px 8px;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: -10px;
    right: 8px;
  }

  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 0 8px 12px 8px;
    border-color: #f2f2f2 transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: -13px;
    right: 8px;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ModalTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
  color: #444444;
`;

const CloseBtn = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const Alarms = styled.div`
  width: 100%;
  height: 120px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const AlarmsEmpty = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const Alarm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding: 12px 0;
  cursor: pointer;
`;

const AlarmListContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const AlarmContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
const AlarmStateContainer = styled.div`
  width: 90px;
`;
const AlarmState = styled.p`
  color: ${(props) =>
    props.status === "미확인" ? "#E96C12" : props.status === "반려" ? "#FF0B0B" : "#444444"};
  font-size: 14px;
  font-weight: 400;
  width: 54px;
`;
const AlarmContent = styled.p`
  color: #444444;
  font-size: 14px;
  font-weight: 400;
  
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const AlarmDate = styled.p`
  color: #909090;
  font-size: 14px;
  font-weight: 400;
  
  white-space: nowrap;
`;
const DeleteAlarmBtn = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

export default function MainHeader(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alarmOpen, setAlarmOpen] = useState(false);
  const [alarmCount, setAlarmCount] = useState(0);
  const [alarmList, setAlarmList] = useState([]);

  const setItem = (item) => {
    props.getItem(item);
  };

  const alarmCountData = async () => {
    try {
      const response = await getNotificationListCount();

      setAlarmCount(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const alarmListData = async () => {
    try {
      const response = await getNotificationList();

      setAlarmList(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteAlarmBtn = (alarm) => {
    dispatch(setConfirm({
      message: "알림을 삭제하시겠습니까?",
      onClick: () => deleteAlarm(alarm)
    }));
  }

  const deleteAlarm = async (alarm) => {
    try {
      await deleteNotification(alarm?.uid);

      dispatch(
          setAlert({
            message: `알림이 삭제되었습니다.`,
            onClick: () => {
              alarmCountData()
              alarmListData()
            }
          })
      );
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    alarmCountData();
    alarmListData();
  }, []);

  return (
    <Header>
      <Logo>
        <img src={image.logo} alt="logo" />
      </Logo>
      <RightBtn onClick={() => setAlarmOpen(!alarmOpen)}>
        <RelativeBox>
          <img src={image.alarm} alt="alarm" />
          <AlarmCount>{alarmCount}</AlarmCount>
        </RelativeBox>
      </RightBtn>
      {alarmOpen && (
        <AlarmModal>
          <ModalContainer>
            <ModalHeader>
              <ModalTitle>작업확인서 알림</ModalTitle>
              <CloseBtn onClick={() => setAlarmOpen(false)}>
                <img src={image.close} alt="close" />
              </CloseBtn>
            </ModalHeader>
            {alarmList.length === 0 && <AlarmsEmpty>알림이 없습니다.</AlarmsEmpty>}
            {alarmList.length > 0 && (
              <Alarms>
                {alarmList.map((alarm, i) => (
                  <Alarm key={i}>
                    <AlarmListContainer>
                      <AlarmContentContainer onClick={() => setItem(alarm)}>
                        <AlarmStateContainer>
                          <AlarmState status={alarm.status}>∙ {alarm.status}</AlarmState>
                          <AlarmDate>{alarm.notificationDate}</AlarmDate>
                        </AlarmStateContainer>
                        <AlarmContent>{alarm.company}</AlarmContent>
                      </AlarmContentContainer>
                      <DeleteAlarmBtn onClick={() => deleteAlarmBtn(alarm)}><img src={image.deleteBasket} alt="delete" /></DeleteAlarmBtn>
                    </AlarmListContainer>
                  </Alarm>
                ))}
              </Alarms>
            )}
          </ModalContainer>
        </AlarmModal>
      )}
    </Header>
  );
}
