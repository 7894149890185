import styled from "styled-components";
import moment from "moment";
import {useEffect, useState} from "react";
import {getDocumentList, getInvoiceList} from "../../../../api/document";
import {useNavigate} from "react-router-dom";

const initSearch = {
    type: "거래명세서",
    company: "전체"
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #202020;
  padding: 40px 16px 24px;
  gap: 24px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  & h1 {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
`;

const Tab = styled.div`
  color: #ffffff;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
`;

const CompanyTab = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: -12px;
  overflow: auto;
  white-space: nowrap;
  max-width: 328px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CompanyTag = styled.div`
  color: #f1f1f1;
  font-size: 15px;
  font-weight: 500;
  background: #202020;
  border: 1px solid #e1e1e1;
  border-radius: 999px;
  padding: 6px 12px;
  cursor: pointer;
`;

const ActiveCompanyTag = styled(CompanyTag)`
  background: #e96c12;
  border: none;
`;

const Dot = styled.div`
  position: absolute;
  top: 0;
  left: -10px;
  width: 6px;
  height: 6px;
  background: #e96c12;
  border-radius: 999px;
`;

const InvoiceItems = styled.div`
  width: 328px;
  height: 580px;
  background: #ffffff;
  border-radius: 12px;
  padding: 8px 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 0;
  }
`;
const Item = styled.div`
  width: 100%;
  padding: 16px 12px;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const EmptyItem = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;
const Left = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #202020;

  & p:last-child {
    font-size: 14px;
    color: #909090;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => {
    if (props.status === "미확인" || props.status === "작성중") {
      return "#E96C12";
    }
    if (props.status === "반려") {
      return "#FF0B0B";
    }

    return "#444444";
  }};
`;
const DocumentItems = styled.div`
  width: 328px;
  height: 580px;
  background: #ffffff;
  border-radius: 12px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  
  &::-webkit-scrollbar {
    width: 0;
  }
`;
const DocumentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 24px;
  height: calc(100% - 48px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;
const ViewHistoryContainer = styled.div`
  position: sticky;
  bottom: 0;
  
  background: #FFFFFF;
  border-top: 1px solid #e1e1e1;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ViewHistory = styled.button`
  font-weight: 600;
  font-size: 15px;
`;

export default function MainListContent(props) {
    const navigate = useNavigate();
    const [search, setSearch] = useState(initSearch);
    const [companys, setCompanys] = useState(["전체"]);

    const [documentList, setDocumentList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);

    const defaultCompany = "전체";
    const startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");

    const setItem = (item) => {
        props.getItem(item);
    };

    const onTabChange = (type) => {
        setSearch((prev) => ({...prev, type}));
    };

    const onCompanyTagChange = (company) => {
        setSearch((prev) => ({...prev, company}));
    };

    const initCompanysData = (response) => {
        const responseCompanys = response.data.data.map((item) => item.company);
        const distinctResponseCompanys = [...new Set(responseCompanys)];
        distinctResponseCompanys.unshift(defaultCompany);

        setCompanys(distinctResponseCompanys);
    };

    const documentListData = async (company) => {
        try {
            const params = {
                company: company,
                startDate: startDate,
                endDate: endDate
            };

            const response = await getDocumentList(params);

            if (companys.length === 1) {
                initCompanysData(response);
            }

            setDocumentList(response.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    const invoiceListData = async () => {
        try {
            const params = {
                company: defaultCompany,
                startDate: startDate,
                endDate: endDate
            };

            const response = await getInvoiceList(params);

            setInvoiceList(response.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        documentListData(search.company);
        invoiceListData();
    }, [search.company]);

    return (
        <Container>
            <Header>
                <h1>전송 내역</h1>
                <TabContainer>
                    <Tab onClick={() => onTabChange("거래명세서")}>
                        {search.type === "거래명세서" && <Dot/>}
                        거래명세서
                    </Tab>
                    <Tab onClick={() => onTabChange("작업확인서")}>
                        {search.type === "작업확인서" && <Dot/>}
                        작업확인서
                    </Tab>
                </TabContainer>
                {search.type === "작업확인서" && companys.length > 0 && (
                    <CompanyTab>
                        {companys.map((company, i) =>
                            company === search.company ? (
                                <ActiveCompanyTag key={i}>{company}</ActiveCompanyTag>
                            ) : (
                                <CompanyTag key={i} onClick={() => onCompanyTagChange(company)}>
                                    {company}
                                </CompanyTag>
                            )
                        )}
                    </CompanyTab>
                )}
            </Header>
            {search.type === "거래명세서" && (
                <InvoiceItems>
                    {invoiceList.length === 0 ? (
                        <EmptyItem>등록된 거래명세서가 없습니다.</EmptyItem>
                    ) : (
                        invoiceList.map((item, i) => (
                            <Item key={i} onClick={() => navigate(`/preview/invoice/${item.invoiceNumber}`)}>
                                <Left>
                                    <p>{item.company}</p>
                                    <p>{moment(item.invoiceDate).format("YYYY-MM-DD")}</p>
                                </Left>
                                <Right status={item.invoiceSendDate.length > 0 ? "전송완료" : "작성중"}>
                                    <p>• {item.invoiceSendDate.length > 0 ? "전송완료" : "작성중"}</p>
                                    <p>
                                        {item.invoiceSendDate.length > 0
                                            ? moment(item.invoiceSendDate).format("YYYY-MM-DD")
                                            : null}
                                    </p>
                                </Right>
                            </Item>
                        ))
                    )}
                </InvoiceItems>
            )}
            {search.type === "작업확인서" && (
                <DocumentItems>
                  <DocumentItemContainer>
                    {documentList.length === 0 ? (
                        <EmptyItem>등록된 작업확인서가 없습니다.</EmptyItem>
                    ) : (
                        documentList.map((item, i) => (
                            <Item key={i} onClick={() => setItem(item)}>
                              <Left>
                                <p>{item.company}</p>
                                <p>{item.workDate}</p>
                              </Left>
                              <Right status={item.status}>
                                <p>• {item.status === "전송됨" ? "확인완료" : item.status}</p>
                              </Right>
                            </Item>
                        ))
                    )}
                  </DocumentItemContainer>
                  <ViewHistoryContainer>
                    <ViewHistory onClick={() => navigate("/document/history")}>전체보기</ViewHistory>
                  </ViewHistoryContainer>
                </DocumentItems>
            )}
        </Container>
    );
}
