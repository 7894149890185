import { useEffect, useState } from "react";
import { getCode } from "../api/user";
import { simpleAlert } from "../store/alert";
import { useDispatch } from "react-redux";

export default function useAuthCode() {

  const dispatch = useDispatch();

  const [isNew, setIsNew] = useState(false);
  const [email, setEmail] = useState(null);

  const [phone, setPhone] = useState("");
  const [phoneButton, setPhoneButton] = useState(true);
  const [phoneReadOnly, setPhoneReadOnly] = useState(false);

  const [code, setCode] = useState("");
  const [codeButton, setCodeButton] = useState(true);
  const [codeReadOnly, setCodeReadOnly] = useState(true);

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const [authCode, setAuthCode] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (phone.trim().length > 0) {
      setPhoneButton(false);
    } else {
      setPhoneButton(true);
    }

    if (code.trim().length > 0) {
      setCodeButton(false);
    } else {
      setCodeButton(true);
    }
  }, [phone, code]);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds > 0 ? seconds - 1 : 0);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const formatTime = () => {
    if (isActive) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    }
    return "";
  };

  const sendCode = async () => {

    const request = {
      phone: phone,
      isNew: isNew,
      email: email
    };

    try {
      const code = await getCode(request);
      setAuthCode(code.data.data);

      dispatch(simpleAlert("인증번호가 발송되었습니다."));
      setSeconds(300);
      setCodeReadOnly(false);
      setIsActive(true);
    } catch (e) {
      dispatch(simpleAlert(e.response.data.message));
    }
  };

  const checkCode = () => {
    if (!code) {
      dispatch(simpleAlert("인증번호를 입력해 주세요."));
    } else if (seconds === 0) {
      dispatch(simpleAlert("인증번호 입력 시간이 초과되었습니다."));
    } else {
      if (code === authCode) {
        dispatch(simpleAlert("인증되었습니다."));
        setIsActive(false);
        setPhoneReadOnly(true);
        setPhoneButton(true);
        setCodeReadOnly(true);
        setCodeButton(true);
        setSuccess(true);
      } else {
        dispatch(simpleAlert("인증번호가 올바르지 않습니다."));
      }
    }
  };

  return {
    email,
    setEmail,
    setIsNew,
    phone,
    setPhone,
    phoneButton,
    setPhoneButton,
    phoneReadOnly,
    setPhoneReadOnly,
    code,
    setCode,
    codeButton,
    setCodeButton,
    codeReadOnly,
    setCodeReadOnly,
    seconds,
    setSeconds,
    setIsActive,
    success,
    formatTime,
    sendCode,
    checkCode
  };
}