import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, modal } from "../../store/modal";
import { Overlay } from "./styles";

const Modal = () => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const { children } = useSelector(modal);

  const modalOutSideClick = (e) => {

    if (modalRef.current === e.target) {
      dispatch(closeModal());
    }
  };

  return (
    <Overlay.Root ref={modalRef} onClick={(e) => modalOutSideClick(e)}>
      {children}
    </Overlay.Root>
  );
};

export default Modal;
