import { InputDiv, InputWrapStyle, WrapDiv } from "./styles";
import Label from "../../label/Label";
import Input from "../../input/Input";
import Button from "../../button/Button";

export default function InputWrap(props) {
  const { label, input, button, $margin, $visible } = props;

  return (
    <>
      <InputWrapStyle $margin={$margin} $visible={$visible}>
        <Label text={label} />

        <WrapDiv>
          <InputDiv>
            <Input {...input} />
          </InputDiv>
          {button && <Button {...button} />}
        </WrapDiv>
      </InputWrapStyle>
    </>
  );
}
