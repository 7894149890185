import GlobalHeader from "../../../layouts/GlobalHeader";
import { backConfirm, setAlert, simpleAlert } from "../../../store/alert";
import ROUTES from "../../../router/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangeButtonDiv, MyDiv } from "../styles";
import InputWrap from "../../../components/wrap/input/InputWrap";
import ButtonWrap from "../../../components/wrap/button/ButtonWrap";
import React, { useEffect, useState } from "react";
import { changeMyPhone } from "../../../api/user";
import useAuthCode from "../../../hook/useAuthCode";

export default function ChangePhone() {
  const {
    setIsNew,
    phone,
    setPhone,
    phoneButton,
    phoneReadOnly,
    setCode,
    codeButton,
    codeReadOnly,
    success,
    formatTime,
    sendCode,
    checkCode
  } = useAuthCode();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [now, setNow] = useState("");
  const [nowWarn, setNowWarn] = useState("");

  useEffect(() => {
    setIsNew(true);
  }, []);

  const exit = () => {
    dispatch(backConfirm(() => navigate(ROUTES.my)));
  };

  const change = async () => {
    if (now === "" || !!nowWarn) {
      dispatch(simpleAlert("기존 전화번호를 확인해 주세요."));
    } else if (!success) {
      dispatch(simpleAlert("전화번호 인증을 해주세요."));
    } else {
      const request = {
        phone: now,
        newPhone: phone
      };

      try {
        await changeMyPhone(request);

        dispatch(
          setAlert({
            message: "전화번호가 변경되었습니다.",
            onClick: () => navigate(ROUTES.my)
          })
        );
      } catch (e) {
        dispatch(simpleAlert(e.response.data.message));
      }
    }
  };

  return (
    <>
      <GlobalHeader title={"전화번호 변경"} left={"닫기"} onLeftClick={() => exit()}></GlobalHeader>

      <MyDiv>
        <InputWrap
          label={"기존 전화번호"}
          input={{
            width: "100%",
            type: "phone",
            placeholder: "기존 전화번호를 입력해 주세요.",
            $warn: nowWarn,
            maxLength: 11,
            mode: "numeric",
            onChange: (e) => setNow(e)
          }}
        />
        <div>
          <InputWrap
            label={"전화번호 인증"}
            input={{
              type: "phone",
              placeholder: "'-' 제외 번호를 입력해 주세요.",
              readOnly: phoneReadOnly,
              onChange: (e) => setPhone(e),
              width: "100%",
              mode: "numeric",
              maxLength: 11
            }}
            button={{
              type: "orange",
              width: "40%",
              height: "48px",
              text: "인증번호 발송",
              radius: "8px",
              fontSize: "16px",
              disabled: phoneButton,
              Click: sendCode
            }}
          />
          <InputWrap
            input={{
              type: "phone",
              placeholder: "인증번호 입력",
              onChange: (e) => setCode(e),
              readOnly: codeReadOnly,
              $option: formatTime(),
              width: "100%",
              mode: "numeric",
              maxLength: 6
            }}
            button={{
              type: "orange",
              width: "40%",
              height: "48px",
              text: "확인",
              radius: "8px",
              fontSize: "16px",
              disabled: codeButton,
              Click: checkCode
            }}
          />
        </div>
      </MyDiv>

      <ChangeButtonDiv>
        <ButtonWrap
          button1={{
            type: "black",
            disable: true,
            width: "100%",
            height: "56px",
            text: "취소",
            radius: "8px",
            fontSize: "18px",
            color: "rgba(68, 68, 68, 1)",
            $back: "#F9F9F9",
            $border: "#F9F9F9",
            Click: () => exit()
          }}
          button2={{
            type: "orange",
            disable: true,
            width: "100%",
            height: "56px",
            text: "변경",
            radius: "8px",
            fontSize: "18px",
            Click: () => change()
          }}
        />
      </ChangeButtonDiv>
    </>
  );
}
