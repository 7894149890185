import styled from "styled-components";
import { image } from "../../themes/theme";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../router/routes";
import {
  APPLE_CALL_URL,
  GOOGLE_CALL_URL,
  KAKAO_CALL_URL,
  NAVER_CALL_URL
} from "../../constant/SocialLogin";
import AppleLogin from "react-apple-login";
import { AppleIcon } from "./index.styled";

const Splash = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;

  & p {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 328px;
  height: 50%;
`;

const Login = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const General = styled.div`
  width: 328px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  gap: 8px;
  cursor: pointer;
  background: #202020;

  & p {
    color: #ffffff;
  }
`;

const SnsLoginBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  height: 48px;

  & a,
  button {
    width: 100%;
    height: 100%;
  }

  & img {
    width: 48px;
    height: 48px;
  }
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #909090;

  .blue a {
    color: #0094ff;
  }

  .orange {
    color: #e96c12;
    cursor: pointer;
    margin-left: 8px;
    text-decoration: #e96c12 underline;
    text-underline-position: under;
  }
`;

const LineDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Line = styled.div`
  flex: 1 0 0;
  width: 150px;
  height: 1px;
  background: #909090;
`;

const StyledLink = styled.a`
  cursor: pointer;
`;

export default function SplashPage() {
  const navigate = useNavigate();

  const handleNaverIconClick = () => {
    window.location.assign(NAVER_CALL_URL);
  };
  const handleKakaoIconClick = () => {
    window.location.assign(KAKAO_CALL_URL);
  };
  const handleGoogleIconClick = () => {
    window.location.assign(GOOGLE_CALL_URL);
  };

  return (
    <Splash>
      <Header>
        <p>작업 확인서를</p>
        <p>공식 문서로 기록해요</p>
      </Header>

      <LoginBox>
        <Login>
          <General className="normal" onClick={() => navigate(ROUTES.join)}>
            <p>계정 만들기</p>
          </General>
          <LineDiv>
            <Line />
            <Text>혹은</Text>
            <Line />
          </LineDiv>
          <SnsLoginBox>
            <StyledLink onClick={handleNaverIconClick}>
              <img src={image.naverIcon} alt="naver" />
            </StyledLink>
            <StyledLink onClick={handleKakaoIconClick}>
              <img src={image.kakaoIcon} alt="kakao" />
            </StyledLink>
            <StyledLink onClick={handleGoogleIconClick}>
              <img src={image.googleIcon} alt="google" />
            </StyledLink>
            <AppleIcon
              onClick={() => {
                document.getElementById("appleid-signin").click();
              }}
            >
              <img src={image.appleIcon} alt="apple" />
              <AppleLogin
                clientId={"idenit.com"}
                scope={"name email"}
                redirectURI={APPLE_CALL_URL}
                responseType={"code id_token"}
                responseMode={"form_post"}
                state="appleloginjjblabstaterandom"
                nonce=""
                usePopup={false}
              />
            </AppleIcon>
          </SnsLoginBox>
          <Text>
            가입 시 당사의{" "}
            <span className="blue">
              <Link to={"/policy/terms"}>이용약관</Link>,{" "}
              <Link to={"/policy/privacy"}>개인정보처리방침</Link>
            </span>
            에 동의하게 됩니다.
          </Text>
        </Login>
        <Text>
          이미 계정이 있으시다면
          <span className="orange" onClick={() => navigate(ROUTES.login)}>
            로그인
          </span>
        </Text>
      </LoginBox>
    </Splash>
  );
}
