import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { login } from "../../../store/user";
import ROUTES from "../../../router/routes";
import { useDispatch } from "react-redux";

const OAuth2Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const oAuthRedirect = async () => {
    const error = searchParams.get("error");
    const token = searchParams.get("token");
    const loginData = searchParams.get("loginData");

    //oAuth 에러
    if (!!error && !token) {
      return navigate(-1, { replace: true });
    }

    //소셜로그인 회원가입
    if (!loginData) {
      const data = jwtDecode(token);
      const userInfo = {
        email: data?.sub
      };

      navigate(ROUTES.sns, {
        replace: true,
        state: {
          userInfo
        }
      });
    }

    //소셜로그인 로그인 진행
    if (loginData) {
      dispatch(
        login({
          loginData: loginData
        })
      );
      navigate(ROUTES.main);
    }
  };

  useEffect(() => {
    oAuthRedirect();
  }, []);

  return <></>;
};

export default OAuth2Login;
